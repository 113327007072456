import React, { useEffect, useState, Fragment } from "react";
import DataTable from "react-data-table-component";
import { TableContainer, Paper, Box } from "@mui/material";
import { getTime, getDate, formatDateUS } from "../helpers";
import LinearIndeterminate from "./ui/LinearIndeterminate";
import FilterComponent from "./ui/FilterComponent";

const tableCustomStyles = {
  headRow: {
    style: {
      textTransform: "uppercase",
      fontWeight: "bold",
      backgroundColor: "#69ddff",
    },
  },
  rows: {
    style: {
      backgroundColor: "#69ddff",
    },
    stripedStyle: {
      backgroundColor: "#FFFFFF",
    },
  },
};

let storageDate =
  localStorage.getItem("search_date") || new Date().toISOString().split("T")[0];

const AirportSchedule = ({ type, airlines, airport }) => {
  const isArriving = type == "destination";

  const columns = [
    {
      name: "Date",
      selector: (row) =>
        formatDateUS(
          getDate(new Date(isArriving ? row.scheduled_in : row.scheduled_out))
        ),
      sortable: true,
    },
    {
      name: `Scheduled ${isArriving ? "In" : "Out"}`,
      selector: (row) =>
        getTime(new Date(isArriving ? row.scheduled_in : row.scheduled_out)),
      sortable: true,
    },
    {
      name: "Ident",
      selector: (row) => row.ident,
      sortable: true,
    },
    {
      name: "Aircraft Type",
      selector: (row) => row.aircraft_type,
      sortable: true,
    },
    {
      name: "Origin",
      selector: (row) => row.origin,
      sortable: true,
    },
    {
      name: "Destination",
      selector: (row) => row.destination,
      sortable: true,
    },
  ];

  const [flights, setFlights] = useState([]);
  const [active, setActive] = useState(false);
  const [searchDate, setSearchDate] = useState(storageDate);
  const [filteredAirlines, setFilteredAirlines] = useState(airlines);

  const newDataSource = async (date) => {
    await airlines.forEach(async (airline) => {
      const response = await fetch(
        `/admin/flights/schedules?airline=${airline}&type=${type}&airport=${airport}&date=${date}&format=json`
      );
      const data = await response.json();
      const filteredData = data.scheduled
        ? data.scheduled.filter((item) => item.ident.includes(airline))
        : [];
      setFlights((prevState) => [...prevState, ...filteredData]);
    });
    return false;
  };

  useEffect(() => {
    newDataSource(searchDate).then((done) => {
      setTimeout(() => {
        setActive(done);
      }, 1000);
    });
  }, []);

  const onDateChangeHandler = (e) => {
    let { value } = e.target;
    setSearchDate(value);
    setActive(true);
    setFlights([]);
    newDataSource(value).then((done) => {
      setTimeout(() => {
        setActive(done);
      }, 1000);
    });
  };

  const filteredFlights = flights.filter((i) =>
    filteredAirlines.includes(i.ident.replace(/[^a-zA-Z]+/g, ""))
  );

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        <Box display="flex" alignContent="space-between" alignItems="center">
          <Box flexGrow={1}>
            <FilterComponent
              label="Airlines"
              values={airlines}
              onFilter={setFilteredAirlines}
            />
          </Box>
          <Box>
            <input
              type="date"
              disabled={active}
              name="search_date"
              value={searchDate}
              onChange={onDateChangeHandler}
              style={{ width: "150px" }}
            />
          </Box>
        </Box>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
        <DataTable
          columns={columns}
          data={filteredFlights}
          striped
          responsive
          pagination
          customStyles={tableCustomStyles}
          progressPending={active}
          progressComponent={<LinearIndeterminate />}
        />
      </TableContainer>
    </Fragment>
  );
};

export default AirportSchedule;
