import React, { Fragment, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { styled } from "@mui/material/styles";
import {
  findIndexInbound,
  findIndexOutbound,
  getTime,
  formatDateUS,
} from "../helpers";
import { green } from "@mui/material/colors";
import axios from "axios";

const searchDate = window.location.search;
const noParams =
  searchDate === "" ||
  (searchDate !== "" &&
    (new Date().getTime() -
      new Date(formatDateUS(searchDate.split("=")[1])).getTime()) /
      (1000 * 3600 * 24) <=
      6);

function createData(
  id,
  logo,
  airline,
  number,
  aircraft,
  time,
  status,
  gate,
  crews,
  type,
  progress_percent,
  rsforms_count,
  report_a,
  report_d
) {
  return {
    id,
    logo,
    airline,
    number,
    aircraft,
    time,
    status,
    gate,
    crews,
    type,
    progress_percent,
    rsforms_count,
    report_a,
    report_d,
  };
}

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#616161EB",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #616161EB",
    borderRadius: "4px",
  },
}));

const SuperSchedule = ({ flightsIn, flightsOut, isOnline, locale }) => {
  const columns = [
    { id: "logo", label: "", minWidth: 25, align: "center" },
    { id: "airline", label: locale("airline"), minWidth: 120, align: "center" },
    { id: "number", label: locale("iata"), minWidth: 60, align: "center" },
    { id: "type", label: locale("flight_type"), minWidth: 30, align: "center" },
    {
      id: "aircraft",
      label: locale("aircraft_type"),
      minWidth: 100,
      align: "center",
    },
    { id: "time", label: locale("sta"), minWidth: 60, align: "center" },
    { id: "status", label: locale("status"), minWidth: 100, align: "center" },
    { id: "gate", label: locale("gate"), minWidth: 50, align: "center" },
    {
      id: "crews",
      label: locale("crew.other"),
      minWidth: 100,
      align: "center",
    },
    { id: "closed", label: locale("closed"), minWidth: 100, align: "center" },
  ];

  const [inbound, setInbound] = useState([]);
  const [outbound, setOutbound] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setInbound(flightsIn);
    setOutbound(flightsOut);
    setActive(flightsIn.length + flightsOut.length === 0);
  }, []);

  const arrival = inbound.map((f) => {
    return {
      id: f.id,
      date: f.flight_date,
      logo: f.logo,
      airline: f.airline,
      aircraft: f.aircraft_type,
      time: f.flight_eta,
      number: f.flight_in,
      status: f.flight_status,
      gate: f.flight_gate,
      crews: f.crews,
      type: "arrival",
      has_report: f.report_a,
      canceled: f.cancel_a,
      ident_icao: f.ident_icao,
      progress_percent: f.progress_percent,
      rsforms_count: f.rsforms_count,
      report_a: f.report_a,
      report_d: f.report_d,
    };
  });

  const departure = outbound.map((f) => {
    return {
      id: f.id,
      logo: f.logo,
      date: f.flight_date,
      airline: f.airline,
      aircraft: f.aircraft_type,
      time: f.flight_etd,
      number: f.flight_out,
      status: f.flight_dstatus,
      gate: f.flight_dgate,
      crews: f.crews,
      type: "departure",
      has_report: f.report_d,
      canceled: f.cancel_d,
      ident_icao: f.ident_icao,
      progress_percent: f.progress_percent,
      rsforms_count: f.rsforms_count,
      report_a: f.report_a,
      report_d: f.report_d,
    };
  });

  const data = [...arrival, ...departure].sort((a, b) => {
    return a.time < b.time ? -1 : 1;
  });

  const rows = data.map((flight) =>
    createData(
      flight.id,
      flight.logo,
      flight.airline,
      flight.number,
      flight.aircraft,
      flight.time,
      flight.canceled ? "Canceled" : flight.status,
      flight.gate,
      flight.crews.map((e) => e["label"].split(" / ")[0]).join(", "),
      flight.type,
      flight.progress_percent,
      flight.rsforms_count,
      flight.report_a,
      flight.report_d
    )
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const refreshBtnClickHandler = () => {
    setActive(true);
    inbound.forEach((s) => {
      if (!s.report_a) {
        getFlightData(s.ident_icao, s.flight_date, "inbound", s.id);
      }
    });
    outbound.forEach((s) => {
      if (!s.report_d) {
        getFlightData(s.ident_icao, s.flight_date, "outbound", s.id);
      }
    });
    const seconds = 300 * (inbound.length + outbound.length);
    setTimeout(() => {
      setActive(false);
    }, seconds);
  };

  const getFlightData = async (identIcao, flightDate, type, serviceId) => {
    const number = identIcao;
    const chars = number.slice(0, number.search(/\d/));
    const numbs = number.replace(chars, "");
    const [year, month, day] = flightDate.split("-");
    let newDataService = {};
    try {
      const response = await fetch(
        `/admin/flights/details?flight=${chars}${numbs}&date=${year}-${month}-${day}&format=json`
      );
      const data = await response.json();
      if (type === "inbound") {
        const inDataPresent = data.flights && data.flights?.length > 0;
        const inDiverted = data.flights && data.flights?.length >= 2;
        const indx = inDiverted ? findIndexInbound(data.flights) : 0;

        newDataService = {
          service: {
            flight_sta: inDataPresent
              ? getTime(data.flights[indx].scheduled_in)
              : "-",
            flight_eta: inDataPresent
              ? getTime(data.flights[indx].estimated_in)
              : "-",
            flight_ata:
              inDataPresent && data.flights[indx].actual_in
                ? getTime(data.flights[indx].actual_in)
                : "-",
            progress_percent: inDataPresent
              ? data.flights[indx].progress_percent
              : 0,
            arrival_delay: inDataPresent ? data.flights[indx].arrival_delay : 0,
            flight_status: inDataPresent ? data.flights[indx].status : "-",
            flight_gate: inDataPresent
              ? data.flights[0].gate_destination ||
                data.flights[indx].gate_destination
              : "-",
          },
        };
      } else {
        const outDataPresent = data.flights && data.flights?.length > 0;
        const outDiverted = data.flights && data.flights?.length >= 2;
        const oIndx = outDiverted ? findIndexOutbound(data.flights) : 0;

        newDataService = {
          service: {
            flight_std: outDataPresent
              ? getTime(data.flights[oIndx].scheduled_out)
              : "-",
            flight_etd: outDataPresent
              ? getTime(data.flights[oIndx].estimated_out)
              : "-",
            flight_atd:
              outDataPresent && data.flights[oIndx].actual_out
                ? getTime(data.flights[oIndx].actual_out)
                : outDataPresent && data.flights[oIndx].actual_off
                ? getTime(data.flights[oIndx].actual_off)
                : "-",
            progress_percent: outDataPresent
              ? data.flights[oIndx].progress_percent
              : 0,
            departure_delay: outDataPresent
              ? data.flights[oIndx].departure_delay
              : 0,
            flight_dstatus: outDataPresent ? data.flights[oIndx].status : "-",
            flight_dgate: outDataPresent
              ? data.flights[0].gate_origin || data.flights[oIndx].gate_origin
              : "-",
          },
        };
      }

      axios
        .put(`/admin/services/${serviceId}.json`, newDataService, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 204) {
            if (type === "inbound") {
              setInbound((prevState) => {
                const newState = [...prevState].map((el) => {
                  if (el.id === serviceId) {
                    el.flight_sta = newDataService.service.flight_sta;
                    el.flight_eta = newDataService.service.flight_eta;
                    el.flight_ata = newDataService.service.flight_ata;
                    el.arrival_delay = parseInt(
                      newDataService.service.arrival_delay
                    );
                    el.progress_percent =
                      newDataService.service.progress_percent;
                    el.flight_status = newDataService.service.flight_status;
                    el.flight_gate = newDataService.service.flight_gate;
                  }
                  return el;
                });
                return newState;
              });
            } else {
              setOutbound((prevState) => {
                const newState = [...prevState].map((el) => {
                  if (el.id === serviceId) {
                    el.flight_std = newDataService.service.flight_std;
                    el.flight_etd = newDataService.service.flight_etd;
                    el.flight_atd = newDataService.service.flight_atd;
                    el.departure_delay = parseInt(
                      newDataService.service.departure_delay
                    );
                    el.progress_percent =
                      newDataService.service.progress_percent;
                    el.flight_dstatus = newDataService.service.flight_dstatus;
                    el.flight_dgate = newDataService.service.flight_dgate;
                  }
                  return el;
                });
                return newState;
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          mb: "20px",
          display: "flex",
          justifyContent: "right",
          width: "100%",
        }}
      >
        {noParams && (
          <Button
            variant="text"
            color="primary"
            startIcon={<RefreshIcon />}
            disabled={!isOnline || active}
            onClick={refreshBtnClickHandler}
          >
            {locale("refresh")}
          </Button>
        )}
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#050505",
                      fontWeight: 600,
                      borderBottomColor: "#e4eaec",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody id="superSchedule">
              {rows.length > 0 &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id + row.type}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ borderBottomColor: "#e4eaec" }}
                            >
                              {column.id === "logo" && (
                                <Avatar
                                  aria-label="logo"
                                  sx={{
                                    width: "25px",
                                    height: "25px",
                                    m: "auto",
                                  }}
                                >
                                  <img src={value} width="25px" height="25px" />
                                </Avatar>
                              )}
                              {column.id === "type" && value === "arrival" && (
                                <Tooltip title={value.toUpperCase()}>
                                  <FlightLandIcon />
                                </Tooltip>
                              )}
                              {column.id === "type" &&
                                value === "departure" && (
                                  <Tooltip title={value.toUpperCase()}>
                                    <FlightTakeoffIcon />
                                  </Tooltip>
                                )}
                              {column.id === "status" && (
                                <HtmlTooltip
                                  title={
                                    <div
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        padding: "4px",
                                      }}
                                    >
                                      <CircularProgressbarWithChildren
                                        value={row["progress_percent"]}
                                        styles={buildStyles({
                                          textColor: "#e4eaec",
                                          pathColor: "#00BBF2",
                                          trailColor: "#e4eaec",
                                        })}
                                      >
                                        <div
                                          style={{
                                            fontSize: 11,
                                            color: "rgba(255,255,255,0.9)",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <strong>
                                            {row["progress_percent"] + "%"}
                                          </strong>
                                        </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  }
                                >
                                  <span
                                    className={
                                      value == "Canceled" ? "text-danger" : null
                                    }
                                  >
                                    {value}
                                  </span>
                                </HtmlTooltip>
                              )}
                              {column.id !== "logo" &&
                                column.id !== "type" &&
                                column.id !== "status" &&
                                value}
                              {column.id === "closed" &&
                                row["type"] === "arrival" &&
                                row["rsforms_count"] > 0 &&
                                row["report_a"] && (
                                  <CheckCircleOutlineIcon
                                    style={{ color: green[600] }}
                                  />
                                )}
                              {column.id === "closed" &&
                                row["type"] === "departure" &&
                                row["report_d"] && (
                                  <CheckCircleOutlineIcon
                                    style={{ color: green[600] }}
                                  />
                                )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}

              {rows.length == 0 && (
                <TableRow role="checkbox" tabIndex={-1}>
                  <TableCell
                    key="1"
                    align="center"
                    colSpan={10}
                    sx={{ borderBottomColor: "#e4eaec", color: "#050505" }}
                  >
                    <i>{locale("no_flights")}</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
};

export default SuperSchedule;
