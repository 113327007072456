import React, { Fragment } from "react";
import { Typography, TextField, Input } from "@mui/material";
import OutlinedDiv from "../ui/OutlinedDiv";

const CommentForm = ({ selectedLine, setSelectedLine, admin, locale }) => {
  return (
    <Fragment>
      <Typography id="new-comment-modal" variant="h6" component="h2">
        {selectedLine.note ? locale("edit") : locale("new")} {locale("comment")}
      </Typography>
      <OutlinedDiv label={locale("changed_sta")} style={{ marginTop: "15px" }}>
        <Input
          type="time"
          value={selectedLine.changed_scheduled_time}
          onChange={(e) =>
            setSelectedLine((prevState) => {
              return {
                ...prevState,
                changed_scheduled_time: e.target.value,
                updated_by: admin.full_name,
              };
            })
          }
          sx={{ width: "100%" }}
        />
      </OutlinedDiv>
      <OutlinedDiv
        label={locale("note")}
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <TextField
          id="registry-line-note"
          size="normal"
          variant="standard"
          value={selectedLine.note}
          onChange={(e) =>
            setSelectedLine((prevState) => {
              return {
                ...prevState,
                note: e.target.value,
                updated_by: admin.full_name,
              };
            })
          }
          sx={{ width: "100%" }}
        />
      </OutlinedDiv>
    </Fragment>
  );
};

export default CommentForm;
