import React, { useRef, useState } from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import SignatureCanvas from "react-signature-canvas";

const SignatureModal = ({ setOpenModal, setImageURL, locale }) => {
  const sigCanvas = useRef();
  const [error, setError] = useState(false);

  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    if (sigCanvas.current.isEmpty()) {
      setError(true);
    } else {
      setError(false);
      setOpenModal(false);
      setImageURL(URL);
    }
  };

  const signatureErrorHandler = () => {
    if (error) {
      setError(false);
    }
  };

  return (
    <div className="modalContainer">
      <div className="modal">
        <div className="sigPadContainer">
          <SignatureCanvas
            penColor="black"
            canvasProps={{ className: "sigCanvas" }}
            ref={sigCanvas}
            onBegin={signatureErrorHandler}
          />
          <hr />
          <Button onClick={() => sigCanvas.current.clear()}>
            {locale("clear")}
          </Button>
        </div>
        {error && (
          <small
            style={{
              color: "#f44336",
              textAlign: "center",
              display: "block",
              margin: "10px auto",
            }}
          >
            {locale("error_messages.signature.not_blank")}
          </small>
        )}
        <div className="modal__bottom">
          <ButtonGroup
            size="large"
            variant="contained"
            aria-label="button group"
          >
            <Button color="error" onClick={() => setOpenModal(false)}>
              {locale("close")}
            </Button>
            <Button color="primary" onClick={create}>
              {locale("save")}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;
