import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Input,
  ButtonGroup,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SignatureModal from "./ui/SignatureModal";
import { formatDateUS, getTime } from "../helpers";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;

const theme = createTheme({
  palette: {
    primary: {
      main: "#303F9F",
    },
    error: {
      main: "#C51162",
    },
  },
});

const CustomerApproval = ({
  trl_form,
  extra_services,
  other_services,
  mostly_used,
  time_services,
  airport,
  number,
  total_manpower,
  menhours_person,
  total_menhours,
  total_hrs,
}) => {
  const [trlForm, setTrlForm] = useState(trl_form);
  const [openModal, setOpenModal] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const newCustomer = trlForm.customer_type === "new";

  let disabled;

  useEffect(() => {
    if (imageURL !== null) {
      handleApproval();
    }
  }, [imageURL, setImageURL]);

  useEffect(() => {
    if (inputValue !== "") {
      handleDecline();
    }
  }, [inputValue, setInputValue]);

  const handleApproval = async () => {
    const date = new Date().toISOString().split("T")[0];
    try {
      const response = await axios.put(
        `/additional_service_forms/${trlForm.id}.json`,
        {
          trl_form: {
            address_line_1: trlForm.address_line_1,
            address_line_2: trlForm.address_line_2,
            phone: trlForm.phone,
            status: "approved",
            signed_by: trlForm.signed_by,
            customer_signature: imageURL,
            date_signed: date,
          },
        }
      );
      if (response.status == 200) {
        setTrlForm((prevState) => {
          return {
            ...prevState,
            customer_signature: imageURL,
            status: "approved",
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecline = async () => {
    const date = new Date().toISOString().split("T")[0];
    try {
      const response = await axios.put(
        `/additional_service_forms/${trlForm.id}.json`,
        {
          trl_form: {
            status: "declined",
            date_signed: date,
            reason: inputValue,
          },
        }
      );
      if (response.status == 200) {
        setTrlForm((prevState) => {
          return {
            ...prevState,
            status: "declined",
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (newCustomer) {
    disabled =
      trlForm.address_line_1 !== "" &&
      trlForm.address_line_2 !== "" &&
      ![null, ""].includes(trlForm.signed_by);
  } else {
    disabled = ![null, ""].includes(trlForm.signed_by);
  }

  const handleDeclineClick = () => {
    withReactContent(Swal).fire({
      title: "Please enter the reason",
      showCancelButton: true,
      confirmButtonText: "Submit",
      denyButtonText: "Cancel",
      confirmButtonColor: "rgb(63, 81, 181)",
      cancelButtonColor: "rgb(245, 0, 87)",
      input: "text",
      inputValue,
      footer: "Triangle Aviation Services",
      preConfirm: () => {
        setInputValue(Swal.getInput()?.value || "");
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      {openModal && (
        <SignatureModal setOpenModal={setOpenModal} setImageURL={setImageURL} />
      )}
      {((trlForm.customer_signature && trlForm.status === "approved") ||
        trlForm.status === "declined") && (
        <div className="wrapper">
          {" "}
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <h1>Offer was {trlForm.status}.</h1>
        </div>
      )}

      {!trlForm.customer_signature && trlForm.status !== "declined" && (
        <div className="container p-sm-3 p-md-5">
          <div className="row">
            <div className="text-center col-12">
              <h2>{inputValue} TRIANGLE SERVICES OF FLORIDA, INC</h2>
              <h6 className="mb-1 text-bold">PO Box # 522168</h6>
              <h6 className="mb-1 text-bold">Miami, FL 33152</h6>
              <h6 className="mb-1 text-bold">
                Phone # (305) 871-8622 ext.9917 * Fax # (305) 871-8621
              </h6>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <p className="mb-1">Ramp Control Phone # (305) 871-6778</p>
              <p className="mb-1">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cell
                # (786) 385-1445
              </p>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-12 col-lg-6">
              <h5>
                <b>RAMP CONTROL:</b> <u>{trlForm.ramp_control}</u>
              </h5>
            </div>
            <div className="col-md-12 col-lg-6">
              <h5>
                <b>SUPERVISOR:</b> <u>{trlForm.supervisor}</u>
              </h5>
            </div>
          </div>
          <div className="row border border-dark">
            <div className="col-md-12 col-lg-6 p-3 border-end border-dark">
              <h5 className="pt-2">
                <b>CUSTOMER: </b> <u>{trlForm.airline}</u>
              </h5>
              {newCustomer && (
                <Fragment>
                  <div className="row">
                    <div className="col-6 pt-3">
                      <h6>&#x2714; *New Customer</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="small mb-0">
                        *Please provide billing address:
                      </p>
                      <Input
                        type="text"
                        name="address_line_1"
                        className="w-100 form-control"
                        value={trlForm.address_line_1}
                        onChange={(e) =>
                          setTrlForm((prevState) => {
                            return {
                              ...prevState,
                              address_line_1: e.target.value,
                            };
                          })
                        }
                      />
                      <label htmlFor="address_line_1">
                        <b>Street</b>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        type="text"
                        name="city_state_zip_code"
                        className="w-100 form-control"
                        value={trlForm.address_line_2}
                        onChange={(e) =>
                          setTrlForm((prevState) => {
                            return {
                              ...prevState,
                              address_line_2: e.target.value,
                            };
                          })
                        }
                      />
                      <div className="d-flex justify-content-between">
                        <label htmlFor="city">
                          <b>City</b>
                        </label>
                        <label htmlFor="state">
                          <b>State</b>
                        </label>
                        <label htmlFor="zip_code">
                          <b>Zip Code</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}

              <div className="row">
                <div className="col-12">
                  {newCustomer && (
                    <Fragment>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="attention_of" className="pt-3">
                            <b>Requested By:</b>
                          </label>
                        </div>
                        <div className="col-9">
                          <Input
                            type="text"
                            name="attention_of"
                            className="w-100 form-control"
                            defaultValue={trlForm.attention_of}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-4">
                              <label htmlFor="phone" className="pt-3">
                                <b>Phone:</b>
                              </label>
                            </div>
                            <div className="col-8">
                              <Input
                                type="text"
                                name="phone"
                                className="w-100 form-control"
                                value={trlForm.phone}
                                onChange={(e) =>
                                  setTrlForm((prevState) => {
                                    return {
                                      ...prevState,
                                      phone: e.target.value,
                                    };
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-4">
                              <label htmlFor="email" className="pt-3">
                                <b>E-Mail:</b>
                              </label>
                            </div>
                            <div className="col-8">
                              <Input
                                type="text"
                                name="email"
                                className="w-100 form-control"
                                defaultValue={trlForm.email}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}

                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-4">
                          <label htmlFor="flight" className="pt-3">
                            <b>Flt #:</b>
                          </label>
                        </div>
                        <div className="col-8">
                          <Input
                            type="text"
                            name="flight"
                            className="w-100 form-control"
                            defaultValue={trlForm.flight_number}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-4">
                          <label htmlFor="tail_number" className="pt-3">
                            <b>Tail #:</b>
                          </label>
                        </div>
                        <div className="col-8">
                          <Input
                            type="text"
                            name="email"
                            className="w-100 form-control"
                            defaultValue={trlForm.tail_number}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="services-performed mt-4">
                <div className="row">
                  <div className="col-12">
                    <h5>
                      <b>Type of Service Performed: </b>
                    </h5>
                    <div className="row">
                      <div className="col-6">
                        <ul style={{ listStyle: "none" }} className="p-1 mb-0">
                          {mostly_used.map((el, index) => {
                            return (
                              <li key={`mu${index}`}>
                                {ReactHtmlParser(
                                  extra_services.includes(el.code)
                                    ? "&#x2714;"
                                    : "&#x2610;"
                                )}{" "}
                                {el.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col-6">
                        <ul style={{ listStyle: "none" }} className="p-1 mb-0">
                          {other_services.map((el, index) => {
                            return (
                              <li key={`os${index}`}>
                                {ReactHtmlParser(
                                  extra_services.includes(el.code)
                                    ? "&#x2714;"
                                    : "&#x2610;"
                                )}{" "}
                                {el.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <hr />
                        <label htmlFor="equipment">
                          <b>Type Equipment Used / Rented:</b>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <ol style={{ padding: "10px 20px" }}>
                          {time_services.map((service) => {
                            return (
                              <li key={service.id}>
                                {service.name == "Equipment Rental"
                                  ? `${service.name} - ${service.unit1}`
                                  : service.name}
                                {service.enable_start_end_time &&
                                  ` | ${getTime(
                                    service.start_time
                                  )} - ${getTime(service.end_time)}`}
                                {service.enable_yes_no &&
                                  (service.enable_unit
                                    ? ` | ${service.label_unit1}: ${service.unit1}`
                                    : service.yes_no
                                    ? " | Yes"
                                    : " | No")}
                                {service.enable_start_loc_dest &&
                                  ` | ${service.start_loc} - ${service.dest}`}
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Input
                          type="text"
                          name="equipment_hrs"
                          className="w-100 form-control"
                          defaultValue={total_hrs}
                          readOnly
                        />
                        <label htmlFor="equipment_hrs">
                          <b>Total Hrs. Equipment Used:</b>
                        </label>
                        <div className="row">
                          <div className="col-3">
                            <label htmlFor="manpower_used" className="pt-3">
                              <b>Manpower Used:</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="manpower_used"
                              className="w-100 form-control text-center"
                              defaultValue={total_manpower}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <label htmlFor="manhours_person" className="pt-3">
                              <b>Manhours/Person:</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="manhours_person"
                              className="w-100 form-control text-center"
                              defaultValue={menhours_person}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <label htmlFor="total_days" className="pt-3">
                                  <b>Total # Days:</b>
                                </label>
                              </div>
                              <div className="col-6">
                                <Input
                                  type="text"
                                  name="total_days"
                                  className="w-100 form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <label htmlFor="total_hours" className="pt-3">
                                  <b>Total Hours:</b>
                                </label>
                              </div>
                              <div className="col-6">
                                <Input
                                  type="text"
                                  name="total_hours"
                                  className="w-100 form-control text-center"
                                  defaultValue={total_menhours}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row hidden">
                          <div className="col-3">
                            <label htmlFor="equipment_cost" className="pt-3">
                              <b>Equipment Cost:</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="equipment_cost"
                              className="w-100 form-control"
                            />
                          </div>
                        </div>
                        <div className="row hidden">
                          <div className="col-3">
                            <label htmlFor="manpower_cost" className="pt-3">
                              <b>Manpower Cost:</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="manpower_cost"
                              className="w-100 form-control"
                            />
                          </div>
                        </div>
                        <div className="row hidden">
                          <div className="col-3">
                            <label htmlFor="sub_cost" className="pt-3">
                              <b>Sub Cost: $</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="sub_cost"
                              className="w-100 form-control"
                            />
                          </div>
                        </div>
                        <div className="row hidden">
                          <div className="col-3">
                            <label htmlFor="port_fees" className="pt-3">
                              <b>Port Fees: $</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="port_fees"
                              className="w-100 form-control"
                            />
                          </div>
                        </div>
                        <div className="row hidden">
                          <div className="col-3">
                            <label htmlFor="sales_tax" className="pt-3">
                              <b>Sales Tax: $</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="sales_tax"
                              className="w-100 form-control"
                            />
                          </div>
                        </div>
                        <div className="row hidden">
                          <div className="col-3">
                            <label htmlFor="total_cost" className="pt-3">
                              <b>Total Cost: $</b>
                            </label>
                          </div>
                          <div className="col-3">
                            <Input
                              type="text"
                              name="total_cost"
                              className="w-100 form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 p-3 small">
              <div className="row mb-5 pt-1">
                <div className="col-6">
                  <h5 className="pt-2">
                    <b>DATE:</b> <u>{formatDateUS(trlForm.trl_date)}</u>
                  </h5>
                </div>
                <div className="col-6">
                  <h3 className="text-bold" style={{ textAlign: "right" }}>
                    ASF No. <span className="text-danger">{number}</span>
                  </h3>
                </div>
              </div>
              {newCustomer && (
                <Fragment>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        In consideration of the mutual covenant herein
                        contained, the undersigned Lessor hereby leases to the
                        undersigned Leasee, upon the terms, covenants and
                        conditions herein set forth, the Motor Vehicle described
                        above, hereinafter referred to as "Vehicle".
                      </p>
                      <ol>
                        <li>
                          Leasee acknowledges that said Vehicle is the property
                          of the Lessor and that it is received in good
                          mechanical condition.
                        </li>
                        <li>
                          Leasee agrees that it will return said vehicle to
                          Lessors's station at <i>{airport}</i> from which it
                          was rented in the same condition as it was received,
                          ordinary wear and tear expected on the return date and
                          hour stated above or sooner, upon demand of the
                          Lessor. In the event that the Leasee does not return
                          the Vehicle on the return date and hour upon demand of
                          the Lessor, the Lessor shall have the right, without
                          further notice or legal process to take possession of
                          the Vehicle and Leasee shall be liable for all costs
                          incurred thereby. The return date and hour goes to the
                          essence of this Agreement since Lessor may make a
                          further commitment for the use of the Vehicle."
                        </li>
                        <li>
                          Leasee agrees not to use said the Vehicle for the
                          transportation of persons or property for hire,
                          express or implied, not to use it for violations of
                          any federal, state or municipal law, ordinance, rule
                          or regulation governing the use or return thereof, not
                          to use it in violation of any rule or regulation of
                          the <b>MDAD</b>, nor to remove it from the{" "}
                          <i>{airport}</i> without the written consent thereto
                          of Lessor.
                        </li>
                        <li>
                          Leasee shall idemnify and hold harmless Triangle
                          Aviation, Inc. and affiliates from any and all claims,
                          liabilities, causes of action, proceedings and any
                          losses, damages, judgments and reasonble costs and
                          expenses incident to defending any such claims, causes
                          of action or proceedings arising out of the Leasee's
                          time and/or operation of the vehicle described in this
                          lease whether as a result of Triangle's negligence or
                          otherwise.
                        </li>
                        <li>
                          Leasee expressly agrees to pay Lessor on demand:
                          <ul>
                            <li style={{ listStyleType: "lower-alpha" }}>
                              A mileage charged computed at the rate above
                              specified for the mileage covered by as Vehicle
                              during the period of this rental.
                            </li>
                            <li style={{ listStyleType: "lower-alpha" }}>
                              Service and time charges computed at the rate
                              specified for the period of this rental.
                            </li>
                            <li style={{ listStyleType: "lower-alpha" }}>
                              A sum equal to the cost of all damages to said
                              Vehicle during the rental period.
                            </li>
                            <li style={{ listStyleType: "lower-alpha" }}>
                              A sum equal to the value of all the tires, tools
                              and accessories lost or stolen from a Vehicle.
                            </li>
                            <li style={{ listStyleType: "lower-alpha" }}>
                              The cost return Vehicle to the station of Lessor
                              at <i>{airport}</i> if vehicle is left at any
                              other point with or without permission.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <hr className="border-dark border my-5" />
                </Fragment>
              )}

              <h5>
                <b>CUSTOMER REPRESENTATIVE</b>
              </h5>
              <div className="row mt-5">
                <div className="col-3">
                  <label htmlFor="signed_by" className="pt-3">
                    <b>PRINT NAME:</b>
                  </label>
                </div>
                <div className="col-9">
                  <Input
                    type="text"
                    name="signed_by"
                    className="w-100 form-control"
                    value={trlForm.signed_by}
                    onChange={(e) =>
                      setTrlForm((prevState) => {
                        return {
                          ...prevState,
                          signed_by: e.target.value.toUpperCase(),
                        };
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-3">
                  <label htmlFor="customer_signature" className="pt-3">
                    <b>SIGNATURE:</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="d-flex justify-content-center">
                    <ButtonGroup
                      size="large"
                      variant="contained"
                      aria-label="button group"
                    >
                      <Button
                        color="primary"
                        onClick={() => setOpenModal(true)}
                        disabled={!disabled}
                      >
                        Sign & Submit
                      </Button>
                      <Button color="error" onClick={handleDeclineClick}>
                        Decline
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <small>
                      {!disabled &&
                        (newCustomer
                          ? "Please enter your billing address and name."
                          : "Please print your name.")}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default CustomerApproval;
