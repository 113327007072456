import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  const { isValid, errorText } = props;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "#d32f2f",
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "#d32f2f",
      },
    }),
  };
  return (
    <React.Fragment>
      <Select styles={customStyles} {...props} />
      <small style={{ color: "rgb(95, 33, 32)" }}>
        {isValid ? "" : errorText}
      </small>
    </React.Fragment>
  );
};

export default CustomSelect;
