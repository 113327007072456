import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({ setTemplates, locale }) {
  const [variantName, setVariantName] = useState([]);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    axios
      .get(`/admin/additional_service_forms/gmr_report?format=json`)
      .then((response) => {
        setVariants(response.data.templates);
        // setVariantName(response.data.templates);
      });
  }, []);

  useEffect(() => {
    setTemplates(variantName);
  }, [variantName, setVariantName, handleChange]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const preventDuplicate = value.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setVariantName(
      // On autofill we get a the stringified value.
      typeof preventDuplicate === "string"
        ? preventDuplicate.split(",")
        : preventDuplicate
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">
          {locale("additional_services")}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          sx={{
            background: "#fff",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;",
          }}
          multiple
          value={variantName}
          onChange={handleChange}
          input={<OutlinedInput label="Additional Services" />}
          renderValue={(selected) => selected.map((x) => x.name).join(", ")}
          MenuProps={MenuProps}
        >
          {variants.map((variant) => (
            <MenuItem key={variant.id} value={variant}>
              <Checkbox checked={variantName.indexOf(variant) > -1} />
              <ListItemText primary={variant.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
