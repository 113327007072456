import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ServiceCard from "./Dashboard/ServiceCard";
import RSForm from "./Service/RSForm";
import RFOReport from "./Service/RFOReport";
import CSReport from "./Service/CSReport";
import SuperSchedule from "./SuperSchedule";
import CommentForm from "./Dashboard/CommentForm";
import i18n from "../i18n";
import { useTranslation, I18nextProvider } from "react-i18next";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  MuiThemeProvider,
  createTheme,
  styled,
} from "@material-ui/core/styles";
import { blue, green } from "@mui/material/colors";
import { onlyUnique } from "../helpers";

import axios from "axios";
import { t } from "i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#00e676",
    },
    success: {
      main: green[600],
    },
  },
});

// Setting up the token for HTTP requests
const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;

const SuperDash = ({
  arrival_services,
  departure_services,
  admin,
  employees,
}) => {
  const Item = styled(Paper)(() => ({
    backgroundColor: "#98d6a9",
    padding: 8,
    textAlign: "center",
    color: "black",
  }));

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const csObject =
    localStorage.getItem("csobject") !== null
      ? JSON.parse(localStorage.getItem("csobject"))
      : {};
  const rsforms = localStorage.getItem("rsforms");
  const rforeports = localStorage.getItem("rforeports");
  const csreports = localStorage.getItem("csreports");
  const tarr1 =
    localStorage.getItem("service_templates") !== null
      ? JSON.parse(localStorage.getItem("service_templates"))
      : [];
  const [showRSForm, setShowRSForm] = useState(false);
  const [showRFOPForm, setShowRFOPForm] = useState(false);
  const [showCSRForm, setShowCSRForm] = useState(false);
  const [clickedService, setClickedService] = useState({});
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [value, setValue] = useState("1");
  const [arrivalServices, setArrivalServices] = useState(arrival_services);
  const [departureServices, setDepartureServices] =
    useState(departure_services);
  const [templates, setTemplates] = useState([]);
  const [type, setType] = useState("");
  const allServices = [
    ...arrivalServices.map((s) => s.id),
    ...departureServices.map((s) => s.id),
  ].filter(onlyUnique);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    i18n.changeLanguage(admin.language);

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    if (isOnline) {
      // Update localStorage service templates
      axios
        .get("/admin/contract_services/only_templates.json")
        .then((response) => {
          if (response.status === 200) {
            const tarr2 = response.data.contract_services;
            const localTemplates = tarr2;
            setTemplates(localTemplates);
            localStorage.setItem(
              "service_templates",
              JSON.stringify(localTemplates)
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });

      allServices.forEach((id) => {
        axios
          .get(`/admin/services/${id}/contract_services`)
          .then((response) => {
            if (response.status === 200) {
              csObject[id] = response.data.contract_services;
            }
            localStorage.setItem("csobject", JSON.stringify(csObject));
          })
          .catch((error) => {
            console.log(error);
          });
      });

      // Send forms to the server if any in localStorage
      if (rsforms !== null) {
        const arrRsForms = JSON.parse(rsforms);
        arrRsForms.forEach((form, index) => {
          axios
            .post("/admin/op201026_forms.json", form)
            .then((response) => {
              if (response.status === 201) {
                const newArrRsForms = arrRsForms.splice(1, index);
                localStorage.setItem("rsforms", JSON.stringify(newArrRsForms));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
      // Send reports to the server if any in localStorage
      if (rforeports !== null) {
        const arrRfoReports = JSON.parse(rforeports);
        arrRfoReports.forEach((report, index) => {
          axios
            .post("/admin/reports.json", report)
            .then((response) => {
              if (response.status === 201) {
                const newArrRfoReports = arrRfoReports.splice(1, index);
                localStorage.setItem(
                  "rforeports",
                  JSON.stringify(newArrRfoReports)
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }

      // Send cabin services reports to the server if any in localStorage
      if (csreports !== null) {
        const arrCsReports = JSON.parse(csreports);
        arrCsReports.forEach((report, index) => {
          axios
            .post("/admin/cabin_services_reports.json", report)
            .then((response) => {
              if (response.status === 201) {
                const newArrCsReports = arrCsReports.splice(1, index);
                localStorage.setItem(
                  "csreports",
                  JSON.stringify(newArrCsReports)
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    } else {
      // If not Online set templates to localStorage
      setTemplates(tarr1);
    }

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  const btnOneClickHandler = () => {
    setShowRSForm(true);
  };

  const btnTwoClickHandler = () => {
    setShowRFOPForm(true);
  };

  const btnThreeClickHandler = () => {
    setShowCSRForm(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateFormsCount = (id) => {
    setArrivalServices((prevState) => {
      const newState = prevState.map((el) => {
        if (el.id == id) {
          el.rsforms_count = 1;
        }
        return el;
      });
      return newState;
    });
  };

  const updateReportField = (id, type) => {
    if (type === "arrival") {
      setArrivalServices((prevState) => {
        const newState = prevState.map((el) => {
          if (el.id == id) {
            el.report_a = true;
          }
          return el;
        });
        return newState;
      });
    }
  };

  const updateReportCSField = (id) => {
    setArrivalServices((prevState) => {
      const newState = prevState.map((el) => {
        if (el.id == id) {
          el.report_cs = true;
        }
        return el;
      });
      return newState;
    });
  };

  const setService = (flight, service) => {
    setType(flight);
    setClickedService(service);
  };

  const saveServiceHandler = (id, comment) => {
    axios
      .patch(`/admin/services/${id}.json`, {
        service: {
          id,
          comment,
        },
      })
      .then((response) => {
        if (response.status === 204) {
          setArrivalServices((prevState) => {
            const newState = prevState.map((el) => {
              if (el.id === id) {
                el.comment = comment;
              }
              return el;
            });
            return newState;
          });
          setDepartureServices((prevState) => {
            const newState = prevState.map((el) => {
              if (el.id === id) {
                el.comment = comment;
              }
              return el;
            });
            return newState;
          });
          handleClose();
        }
      });
  };

  const arrivingFlights = arrivalServices.map((service) => {
    if (
      (service.csr_required &&
        !service.report_cs &&
        !service.cancel_a &&
        admin.cleaning) ||
      (!service.report_a && !service.cancel_a) ||
      (service.rsforms_count <= 0 && !service.cancel_a && admin.ramp)
    ) {
      return (
        <ServiceCard
          handleOpen={handleOpen}
          service={service}
          isOnline={isOnline}
          type="arrival"
          key={service.id}
          setServices={setArrivalServices}
          displayBtnOne={admin.ramp}
          displayBtnTwo={admin.ramp}
          displayBtnThree={service.csr_required && admin.cleaning}
          btnOneClickHandler={btnOneClickHandler}
          btnTwoClickHandler={btnTwoClickHandler}
          btnThreeClickHandler={btnThreeClickHandler}
          setService={setService}
          locale={t}
        />
      );
    }
  });

  const departingFlights = departureServices.map((service) => {
    if (!service.report_d && !service.cancel_d) {
      return (
        <ServiceCard
          handleOpen={handleOpen}
          service={service}
          isOnline={isOnline}
          type="departure"
          key={service.id}
          setServices={setDepartureServices}
          displayBtnOne={false}
          displayBtnTwo={admin.ramp}
          displayBtnThree={false}
          btnTwoClickHandler={btnTwoClickHandler}
          setService={setService}
          locale={t}
        />
      );
    }
  });

  return (
    <I18nextProvider i18n={i18n}>
      <CommentForm
        open={open}
        handleClose={handleClose}
        service={clickedService}
        setService={setClickedService}
        saveServiceHandler={saveServiceHandler}
        locale={t}
      />
      {showRSForm && (
        <MuiThemeProvider theme={theme}>
          <RSForm
            adminId={admin.id}
            service={clickedService}
            closeBtnClick={setShowRSForm}
            isOnline={isOnline}
            updateFormsCount={updateFormsCount}
            locale={t}
          />
        </MuiThemeProvider>
      )}
      {showRFOPForm && (
        <MuiThemeProvider theme={theme}>
          <RFOReport
            service={clickedService}
            flightType={type}
            updateService={updateReportField}
            closeBtnClick={setShowRFOPForm}
            isOnline={isOnline}
            templates={templates.filter((t) => t.group_name !== "Cabin")}
            adminId={admin.id}
            names={employees}
            contractServices={csObject[clickedService.id]}
            locale={t}
          />
        </MuiThemeProvider>
      )}
      {showCSRForm && (
        <MuiThemeProvider theme={theme}>
          <CSReport
            service={clickedService}
            isOnline={isOnline}
            templates={templates.filter((t) => t.group_name === "Cabin")}
            contractServices={csObject[clickedService.id]}
            adminId={admin.id}
            updateService={updateReportCSField}
            updateArrivalCards={setArrivalServices}
            closeBtnClick={setShowCSRForm}
          />
        </MuiThemeProvider>
      )}
      {!showCSRForm && !showRSForm && !showRFOPForm && (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="Supervisor Schedule">
                <Tab label={t("schedule")} value="1" />
                <Tab label={t("arrival")} value="2" />
                <Tab label={t("departure")} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ minHeight: "60vh" }}>
              <Grid container spacing={1}>
                <SuperSchedule
                  isOnline={isOnline}
                  flightsIn={arrival_services}
                  flightsOut={departure_services}
                  locale={t}
                />
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ minHeight: "60vh" }}>
              <Grid container spacing={Math.round(arrivingFlights.length / 3)}>
                {arrivingFlights}
              </Grid>
            </TabPanel>
            <TabPanel value="3" sx={{ minHeight: "60vh" }}>
              <Grid container spacing={Math.round(departingFlights.length / 3)}>
                {departingFlights}
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </I18nextProvider>
  );
};

export default SuperDash;
