export const customStyles = {
  content: {
    minWidth: "466px",
    height: "",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "20px",
    transform: "translate(-50%, -50%)",
    padding: "20px 20px 40px",
    overflowY: "auto",
  },
};
