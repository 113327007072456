import React, { Fragment } from "react";
import { Typography, TextField, Input } from "@mui/material";
import OutlinedDiv from "../ui/OutlinedDiv";

const GateChangeForm = ({ selectedLine, setSelectedLine, admin, locale }) => {
  console.log(selectedLine);
  return (
    <Fragment>
      <Typography id="change-gate-modal" variant="h6" component="h2">
        {locale("update")} {locale("gate_w")}
      </Typography>
      <OutlinedDiv
        label={locale("new") + " " + locale("gate_w")}
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <TextField
          id="registry-line-gate"
          size="normal"
          variant="standard"
          value={selectedLine.gate}
          onChange={(e) =>
            setSelectedLine((prevState) => {
              return {
                ...prevState,
                gate: e.target.value,
                updated_by: admin.full_name,
              };
            })
          }
          sx={{ width: "100%" }}
        />
      </OutlinedDiv>
    </Fragment>
  );
};

export default GateChangeForm;
