import React, { useEffect, useState } from "react";
import TimelineComponent from "./ui/TimelineComponent";
import TabPanel from "./ui/TabPanel";
import { Tab, Tabs, Box } from "@mui/material";
import i18n from "../i18n";
import { useTranslation, I18nextProvider } from "react-i18next";

const ManpowerSchedule = ({ admin }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(admin.language);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          padding: "15px 20px",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="schedule tabs">
          {[
            t("ramp"),
            t("cabin"),
            t("security"),
            t("cargo"),
            t("ramp_control"),
            t("porter"),
          ].map((tabName) => (
            <Tab label={tabName} key={tabName} />
          ))}
        </Tabs>
        {["Ramp", "Cabin", "Security", "Cargo", "Ramp Control", "Porter"].map(
          (tabName, index) => {
            return (
              <TabPanel
                key={index}
                value={value}
                index={index}
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <TimelineComponent tabName={tabName} />
              </TabPanel>
            );
          }
        )}
      </Box>
    </I18nextProvider>
  );
};

export default ManpowerSchedule;
