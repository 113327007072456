import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Appointment from "../Appointment";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  DayView,
  MonthView,
  ViewSwitcher,
  Toolbar,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  ConfirmationDialog,
  DragDropProvider,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import axios from "axios";
import alertify from "alertifyjs";
import { formatDate } from "../../helpers";
import { useTranslation } from "react-i18next";

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);

const Calendar = ({ date, admin, can_create }) => {
  const { t, i18n } = useTranslation();

  const [services, setServices] = useState([]);

  const getSchedule = () => {
    axios
      .get(`/admin/services/scheduler?admin_id=${admin.id}`)
      .then((response) => {
        setServices(response.data.services);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    i18n.changeLanguage(admin.language);
    getSchedule();
  }, []);

  // Update Appointments every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getSchedule();
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  // End of Update Appointments every 10 seconds

  const commitChanges = async ({ changed, deleted }) => {
    if (changed) {
      const id = Object.keys(changed)[0];
      const newDate = formatDate(changed[id].endDate);
      const startDate = new Date(changed[id].startDate);
      const endDate = new Date(changed[id].endDate);
      const startTime = `${
        startDate.getHours() < 10
          ? `0${startDate.getHours()}`
          : startDate.getHours()
      }:${
        startDate.getMinutes() < 10
          ? `0${startDate.getMinutes()}`
          : startDate.getMinutes()
      }`;
      const endTime = `${
        endDate.getHours() < 10 ? `0${endDate.getHours()}` : endDate.getHours()
      }:${
        endDate.getMinutes() < 10
          ? `0${endDate.getMinutes()}`
          : endDate.getMinutes()
      }`;
      const updatedService = id.includes("arrival")
        ? {
            service: {
              flight_date: newDate,
              time_from: startTime,
              time_to: endTime,
            },
          }
        : {
            service: {
              flight_date: newDate,
              time_dfrom: startTime,
              time_dto: endTime,
            },
          };
      let changedService = services.find((i) => i.id == id);

      if (
        !changedService.startDate.includes(newDate) ||
        (startTime === "00:00" && endTime === "00:00")
      ) {
        alertify.error("Error: The time range should be within the same date.");
      } else {
        const response = await axios.put(
          `/admin/services/${id}.json`,
          updatedService
        );
        if (response.status === 204) {
          setServices((prevState) => {
            changedService.flight_date = newDate;
            changedService.startDate = `${newDate}T${startTime}`;
            changedService.endDate = `${newDate}T${endTime}`;
            const newState = prevState.filter((el) => el.id != id);
            return [...newState, changedService];
          });
        }
      }
    }

    if (deleted) {
      try {
        const response = await axios.post(
          `/admin/services/${deleted}/modify.json`
        );
        if (response.data.status === 204) {
          alertify.success("Service was successfully deleted.");
          setServices((prevState) => {
            const newState = prevState.filter((el) => el.id != deleted);
            return newState;
          });
        }
      } catch (error) {
        alertify.error(error.message);
      }
    }
  };

  return (
    <div>
      <h2 className="dh2">{t("calendar")}</h2>
      <Paper>
        <Scheduler data={services} height={660}>
          <ViewState defaultCurrentDate={date} />
          <EditingState onCommitChanges={can_create ? commitChanges : null} />
          <IntegratedEditing />

          <MonthView />
          <WeekView startTime={1} endTime={24} />
          <DayView />
          <Toolbar />
          <ViewSwitcher />
          <DateNavigator />
          <TodayButton />
          <ConfirmationDialog
            messages={{
              confirmDeleteMessage:
                "Are you sure you want to delete this service?",
            }}
          />
          <Appointments appointmentComponent={Appointment} />
          <AppointmentTooltip showDeleteButton showCloseButton />
          <DragDropProvider />
        </Scheduler>
      </Paper>
    </div>
  );
};

export default Calendar;
