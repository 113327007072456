import React, { Fragment, useState, useEffect } from "react";
import TimeInput from "material-ui-time-picker";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import {
  TextField,
  Typography,
  InputLabel,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  IconButton,
  Input,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import SignatureModal from "./SignatureModal";
import { validateService, num2time } from "../../helpers";
import { red } from "@mui/material/colors";

const AdminContractService = ({
  service,
  onChange,
  onDelete,
  errors,
  setErrors,
  serviceCode,
  index,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    onChange(serviceCode, service.id, "signature", imageURL);
    errors.signature = imageURL == null;
  }, [imageURL, setImageURL]);

  const validateInputHandler = (e) => {
    const validated = validateService(service);
    const { name } = e.target;
    errors[name] = validated[name];
    setErrors((prevState) => {
      const newState = { ...prevState };
      newState[service.id.toString()] = { ...errors };
      return newState;
    });
  };

  return (
    <Fragment>
      <Grid sx={{ py: 2, px: 3, borderTop: "2px solid #f5f5f5" }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: "#2f353f",
              marginLeft: "10px",
            }}
            variant="body1"
          >
            {!["RMP0010", "RMP0009", "RMP0023"].includes(service.code) && index}
            {service.code === "RMP0010" && `Unit ${index}`}
            {service.code === "RMP0009" && `Unit ${index}`}
            {service.code === "RMP0023" && `Agent ${index}`}
          </Typography>

          {service.name === "Cabin Services" ? (
            <br />
          ) : service.shared && !arrival ? (
            <br />
          ) : (
            <IconButton onClick={onDelete} id={service.id}>
              <DeleteOutlinedIcon sx={{ color: red[600] }} id={service.id} />
            </IconButton>
          )}
        </Grid>
        {service.enable_yes_no && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{ paddingBottom: "10px" }}
          >
            {service.label_yes_no !== "Yes" && (
              <Grid item xs={6} md={2}>
                <FormLabel id={"yes-no-controlled-radio-buttons-group"}>
                  {service.label_yes_no}
                </FormLabel>
              </Grid>
            )}
            <Grid item xs={6} md={4}>
              <RadioGroup
                row
                aria-labelledby={"yes-no-controlled-radio-buttons-group"}
                name="controlled-radio-buttons-group"
                value={service.yes_no}
                onChange={(event) =>
                  onChange(
                    serviceCode,
                    service.id,
                    "yes_no",
                    event.target.value
                  )
                }
                id="yes-no"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                {/* <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                /> */}
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        {service.enable_unit && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{ paddingBottom: "10px" }}
          >
            {service.label_unit1 && (
              <Fragment>
                <Grid item xs={2} md={2}>
                  <InputLabel htmlFor="unit1">{service.label_unit1}</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.unit1}
                    helperText={
                      errors.unit1 ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_unit1}
                    id="unit1"
                    name="unit1"
                    type="text"
                    value={service.unit1}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "unit1",
                        event.target.value
                      )
                    }
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
            {service.label_unit2 && (
              <Fragment>
                <Grid item xs={2} md={2}>
                  <InputLabel htmlFor="unit2">{service.label_unit2}</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.unit2}
                    helperText={
                      errors.unit2 ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_unit2}
                    id="unit2"
                    name="unit2"
                    type="text"
                    value={service.unit2}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "unit2",
                        event.target.value
                      )
                    }
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
            {service.label_unit3 && (
              <Fragment>
                <Grid item xs={2} md={2}>
                  <InputLabel htmlFor="unit3">{service.label_unit3}</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.unit3}
                    helperText={
                      errors.unit3 ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_unit3}
                    id="unit3"
                    name="unit3"
                    type="text"
                    value={service.unit3}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "unit3",
                        event.target.value
                      )
                    }
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
            {service.label_unit4 && (
              <Fragment>
                <Grid item xs={2} md={2}>
                  <InputLabel htmlFor="unit4">{service.label_unit4}</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.unit4}
                    helperText={
                      errors.unit4 ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_unit4}
                    id="unit4"
                    name="unit4"
                    value={service.unit4}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "unit4",
                        event.target.value
                      )
                    }
                    type="text"
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
            {service.label_unit5 && (
              <Fragment>
                <Grid item xs={2} md={2}>
                  <InputLabel htmlFor="unit5">{service.label_unit5}</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.unit5}
                    helperText={
                      errors.unit5 ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_unit5}
                    id="unit5"
                    name="unit5"
                    type="text"
                    value={service.unit5}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "unit5",
                        event.target.value
                      )
                    }
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        )}
        {service.enable_start_loc_dest && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{ paddingBottom: "10px" }}
          >
            {service.label_start_loc && (
              <Fragment>
                <Grid item xs={2} md={1}>
                  <InputLabel htmlFor="start_loc">
                    {service.label_start_loc}
                  </InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.start_loc}
                    helperText={
                      errors.start_loc ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_start_loc}
                    id="start_loc"
                    name="start_loc"
                    type="text"
                    value={service.start_loc}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "start_loc",
                        event.target.value
                      )
                    }
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
            {service.label_dest && (
              <Fragment>
                <Grid item xs={2} md={1}>
                  <InputLabel htmlFor="dest">{service.label_dest}</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    error={errors.dest}
                    helperText={
                      errors.dest ? "This field cannot be blank." : null
                    }
                    variant="standard"
                    onBlur={validateInputHandler}
                    placeholder={service.label_dest}
                    id="dest"
                    name="dest"
                    type="text"
                    value={service.dest}
                    onChange={(event) =>
                      onChange(
                        serviceCode,
                        service.id,
                        "dest",
                        event.target.value
                      )
                    }
                    fullWidth={true}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        )}
        {service.enable_start_end_time && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{ paddingBottom: "10px" }}
          >
            {service.label_start_time && (
              <Fragment>
                <Grid item xs={2} md={1}>
                  <InputLabel htmlFor="start_time">
                    {service.label_start_time}
                  </InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TimeInput
                    onBlur={validateInputHandler}
                    id="start_time"
                    name="start_time"
                    mode="24h"
                    style={{ width: "100%" }}
                    onChange={(time) => {
                      onChange(serviceCode, service.id, "start_time", time);
                    }}
                    value={
                      service.start_time
                        ? new Date(service.start_time)
                        : new Date()
                    }
                  />
                  {errors.start_time && (
                    <small style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                      {service.label_start_time
                        .split(" ")
                        .map((w) => w.toLowerCase())
                        .includes("time")
                        ? null
                        : "time"}{" "}
                      can't be blank
                    </small>
                  )}
                </Grid>
              </Fragment>
            )}

            {service.label_end_time && (
              <Fragment>
                <Grid item xs={2} md={1}>
                  <InputLabel htmlFor="end_time">
                    {service.label_end_time}
                  </InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TimeInput
                    onBlur={validateInputHandler}
                    id="end_time"
                    name="end_time"
                    mode="24h"
                    onChange={(time) => {
                      onChange(serviceCode, service.id, "end_time", time);
                    }}
                    value={
                      service.end_time ? new Date(service.end_time) : new Date()
                    }
                    style={{ width: "100%" }}
                  />
                  {errors.end_time && (
                    <small style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                      can't be equal to {service.label_start_time} time
                    </small>
                  )}
                </Grid>
              </Fragment>
            )}

            {service.label_start_time && service.label_end_time && (
              <Fragment>
                <Grid item xs={2} md={1}>
                  <InputLabel htmlFor="end_time">Total of HR</InputLabel>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Input
                    readOnly
                    type="text"
                    value={num2time(
                      service.end_time.getTime() - service.start_time.getTime()
                    )}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        )}
        {openModal && (
          <SignatureModal
            setOpenModal={setOpenModal}
            setImageURL={setImageURL}
          />
        )}
        {service.signature_required && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{ paddingBottom: "10px" }}
          >
            <Grid item xs={2} md={1}>
              <InputLabel htmlFor="dest">Signer Name</InputLabel>
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                error={errors.signer_name}
                helperText={
                  errors.signer_name ? "This field cannot be blank." : null
                }
                variant="standard"
                onBlur={validateInputHandler}
                id="signer_name"
                name="signer_name"
                fullWidth={true}
                value={service.signer_name}
                placeholder="Signer Name"
                onChange={(event) =>
                  onChange(
                    serviceCode,
                    service.id,
                    "signer_name",
                    event.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="outlined"
                color="info"
                onClick={() => setOpenModal(true)}
              >
                <CreateIcon /> Signature
              </Button>
              {errors.signature && (
                <small style={{ color: "#d32f2f" }}>
                  Please create a signature
                </small>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default AdminContractService;
