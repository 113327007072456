// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons";

import "@fortawesome/fontawesome-free/css/all.css";
import "arctic_admin";

import "activeadmin_quill_editor";

import "../custom/companion";

import "chartkick/chart.js";

require("trix")
require("@rails/actiontext")

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(function () {
  const { pathname } = window.location;
  const alertBox = document.getElementsByClassName("flashes")[0];
  if (
    pathname === "/admin/uncompleted_services" ||
    pathname.includes("admin/admin_users/")
  ) {
    alertBox.innerHTML = "";
  }

  setTimeout(() => {
    if (alertBox.hasChildNodes()) {
      const firstChild = alertBox.firstChild.nextSibling;
      if (
        firstChild.classList &&
        firstChild.classList.contains("flash_notice")
      ) {
        alertBox.removeChild(firstChild);
      }
    }
  }, 3000);

  if (pathname.includes("admin/admin_users") && pathname.includes("edit")) {
    $("#my_account a.menu_item").addClass("current");
  }
  if (window.innerWidth > 991) {
    const node = document.querySelector("#tabs");
    onClassChange(node, (observer) => {
      if (node.classList.contains("tabs_open")) {
        $("body.active_admin.logged_in").animate({ paddingLeft: "250px" }, 475);
      } else {
        $("body.active_admin.logged_in").animate({ paddingLeft: "0" }, 475);
      }
    });
  }
});

window.completeCustomerFields = function (e) {
  let url = `/admin/customers/${e.value}.json`;
  sendGetRequest(url, setCustomerCodeAndNumber);

  function setCustomerCodeAndNumber(data) {
    document.getElementById("contract_customer_code").value = data.customerid;
    document.getElementById("contract_customer_number").value = data.recordno;
  }
};

window.showHideSuperFields = function (e) {
  if (e.value == "ramp_supervisor") {
    $("#ramp_supervisor_fields").removeClass("hidden").hide().fadeIn("slow");
  } else {
    $("#ramp_supervisor_fields").addClass("hidden");
  }
};

function onClassChange(node, callback) {
  let lastClassString = node.classList.toString();

  const mutationObserver = new MutationObserver((mutationList) => {
    for (const item of mutationList) {
      if (item.attributeName === "class") {
        const classString = node.classList.toString();
        if (classString !== lastClassString) {
          callback(mutationObserver);
          lastClassString = classString;
          break;
        }
      }
    }
  });

  mutationObserver.observe(node, { attributes: true });

  return mutationObserver;
}

document.addEventListener("DOMContentLoaded", () => {
  const form = document.querySelector("form[action*='update_reviewer']");
  if (form) {
    attachFormSubmitListener(form);
  }

  function attachFormSubmitListener(form) {
    form.addEventListener("submit", (event) => {
      event.preventDefault();
      const formData = new FormData(form);
      const url = form.action;
      sendPostRequest(url, formData, (data) =>
        updateReviewerSection(data.reviewer)
      );
    });
  }

  function sendPostRequest(url, body, callback) {
    fetch(url, {
      method: "POST",
      body: body,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log("Operation successful");
          callback(data);
        } else {
          console.error("Operation failed:", data.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  function updateReviewerSection(reviewer) {
    const reviewerSection = document.querySelector(".reviewers-section");
    reviewerSection.innerHTML = "<h3>Reviewer</h3>";

    if (reviewer.id) {
      reviewerSection.innerHTML += `
        <div id="assigned-reviewers" class="reviewer-list" data-registry-id="${reviewer.id}" data-full-name="${reviewer.full_name}">
          <div class="reviewer">
            <span>${reviewer.full_name}</span>
            <button class="remove-reviewer-btn" data-reviewer-id="${reviewer.id}" data-registry-id="${reviewer.id}">Remove</button>
          </div>
        </div>
      `;
      attachRemoveButtonListener(
        reviewerSection.querySelector(".remove-reviewer-btn")
      );
    } else {
      fetchReviewersAndPopulateDropdown(reviewerSection);
    }
  }

  function attachRemoveButtonListener(button) {
    button.onclick = () => removeReviewer(button.dataset.registryId);
  }

  function fetchReviewersAndPopulateDropdown(reviewerSection) {
    const registryId = window.location.href.split("/").pop();
    fetch(`/admin/registries/${registryId}/reviewers_by_airport`, {
      headers: { Accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        const reviewerOptions = data.reviewers
          .map(
            (user) => `<option value="${user.id}">${user.full_name}</option>`
          )
          .join("");
        reviewerSection.innerHTML += `
        <form action="/admin/registries/${registryId}/update_reviewer" method="post">
          <select name="reviewer_id" class="select-input p-10">
            <option value="">Select a reviewer</option>
            ${reviewerOptions}
          </select>
          <input type="submit" value="Add Reviewer" class="button p-10">
        </form>
      `;
        attachFormSubmitListener(reviewerSection.querySelector("form"));
      })
      .catch((error) => console.error("Error fetching reviewers:", error));
  }

  function removeReviewer(registryId) {
    sendPostRequest(
      `/admin/registries/${registryId}/update_reviewer`,
      JSON.stringify({ reviewer_id: null }),
      () => {
        console.log("Reviewer removed successfully");
        updateReviewerSection({ id: null, full_name: "No reviewer assigned" });
      }
    );
  }

  const existingRemoveButton = document.querySelector(".remove-reviewer-btn");
  if (existingRemoveButton) {
    attachRemoveButtonListener(existingRemoveButton);
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("approval-form");
  if (form) {
    form.addEventListener("submit", function (event) {
      console.log("Form submitted");
    });
  } else {
    console.log("Form not found");
  }
  var dropdownToggle = document.querySelector(".dropdown-toggle");
  var languageLinks = document.querySelectorAll(".dropdown-item");

  if (dropdownToggle) {
    dropdownToggle.addEventListener("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
    });
  }

  languageLinks.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.stopPropagation();
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("approval-form-2");
  if (form) {
    form.addEventListener("submit", function (event) {
      console.log("Form submitted");
    });
  } else {
    console.log("Form not found");
  }
});

function sendGetRequest(url, callback) {
  fetch(url, {
    method: "GET",
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    });
}
