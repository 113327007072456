import React from "react";
import Calendar from "./Dashboard/Calendar";
import Schedule from "./Dashboard/Schedule";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";

const AdminDashboard = ({
  flights,
  airports,
  limits,
  can_create,
  date,
  admin,
}) => {
  return (
    <I18nextProvider i18n={i18n}>
      <Schedule
        flights={flights}
        airports={airports}
        limits={limits}
        can_create={can_create}
        admin={admin}
      />
      <Calendar date={date} admin={admin} can_create={can_create} />
    </I18nextProvider>
  );
};

export default AdminDashboard;
