import * as React from "react";
import {Appointments} from '@devexpress/dx-react-scheduler-material-ui';

const Appointment = ({children, style, ...restProps}) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: restProps.data.color
    }}
  >
    {children}
  </Appointments.Appointment>
);

export default Appointment;