import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  IconButton,
  TextField,
  FormLabel,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  ButtonGroup,
  Button,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AirlinesIcon from "@mui/icons-material/Airlines";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FlightIcon from "@mui/icons-material/Flight";
import NumbersIcon from "@mui/icons-material/Numbers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ContractService from "../ui/ContractService";
import ReportModal from "./ReportModal";
import OutlinedDiv from "../ui/OutlinedDiv";
import LinearProgress from "@material-ui/core/LinearProgress";
import { formatDateUS, validateService, clearService } from "../../helpers";
import alertify from "alertifyjs";
import axios from "axios";

const listStyle = { width: "100%", bgcolor: "background.paper" };
const avatarStyle = { backgroundColor: "#00BBF2" };
const textColor = "#050505";
const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);

const CSReport = ({
  service,
  closeBtnClick,
  templates,
  contractServices,
  adminId,
  updateArrivalCards,
  updateService,
  isOnline,
}) => {
  const [servicesByContract, setServicesByContract] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const filteredTemplates = templates
    .filter((t) => t["arrival"] === true)
    .sort((a, b) => {
      return a.priority <= b.priority ? -1 : 1;
    });
  const [openModal, setOpenModal] = useState(false);
  const [extraStepFinished, setExtraStepFinished] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [quality, setQuality] = useState("");
  const [qualityError, setQualityError] = useState(false);
  const [comments, setComments] = useState("");
  const [commentsError, setCommentsError] = useState(false);
  const [commentsRequired, setCommentsRequired] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCommentsRequired(quality === "Unacceptable");
    setCommentsError(false);
    setQualityError(false);
  }, [quality, setQuality]);

  useEffect(() => {
    setDescriptionError(false);
  }, [description, setDescription]);

  useEffect(() => {
    const arr1 = [];
    const arr2 = [];
    for (let i = 0; i < filteredTemplates.length; i++) {
      contractServices.includes(filteredTemplates[i].id.toString())
        ? arr1.push(
            clearService(
              filteredTemplates[i],
              service.flight_date,
              service.flight_sta
            )
          )
        : arr2.push(
            clearService(
              filteredTemplates[i],
              service.flight_date,
              service.flight_sta
            )
          );
    }
    setServicesByContract(arr1);
    setAdditionalServices(arr2);
    const timeOut = setTimeout(() => {
      setShowForm(true);
    }, 100 * contractServices.length);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const addServices = (ids) => {
    ids.forEach((id) => {
      let event = { target: { value: id } };
      addServiceHandler(event);
    });
    setExtraStepFinished(true);
    setIsDisabled(false);
  };

  const addServiceHandler = (event) => {
    const id = event.target.value;
    let selected = filteredTemplates.filter((s) => s.id === id)[0];
    setSelectedService(id);
    if (selected) {
      selected = clearService(
        selected,
        service.flight_date,
        service.flight_sta
      );
      setServicesByContract((prevState) => {
        let newState = [...prevState, selected];
        newState = newState.sort((a, b) => {
          if (a.priority < b.priority) {
            return -1;
          }
          if (a.priority > b.priority) {
            return 1;
          }
          return 0;
        });
        return newState;
      });
      setTimeout(() => {
        setSelectedService("");
        setAdditionalServices((prevState) => {
          const newState = prevState.filter((s) => s.id !== id);
          return newState;
        });
      }, 1000);
    }
  };

  const removeServiceHandler = (event) => {
    const { id } = event.target.parentElement;
    let selected = filteredTemplates.filter((s) => s.id.toString() === id)[0];
    if (selected) {
      alertify
        .confirm(
          "Triangle Services Aviation",
          "Would you like to delete this service?",
          function () {
            document.querySelector(
              ".alertify.ajs-movable.ajs-closable"
            ).style.display = "none";
            selected = clearService(
              selected,
              service.flight_date,
              service.flight_sta
            );
            setServicesByContract((prevState) => {
              const newState = prevState.filter((s) => s.id.toString() !== id);
              return newState;
            });
            setAdditionalServices((prevState) => [...prevState, selected]);
            alertify.success("Deleted");
          },
          function () {
            document.querySelector(
              ".alertify.ajs-movable.ajs-closable"
            ).style.display = "none";
            alertify.error("Cancelled");
          }
        )
        .set("labels", { ok: "Yes", cancel: "No" });
    }
  };

  const fieldChangeHandler = (id, field, value) => {
    const updatedService = servicesByContract.filter((s) => s.id === id)[0];
    updatedService[field] = value;
    setServicesByContract((prevState) => {
      const newState = prevState.map((s) => {
        return s.id === id ? updatedService : s;
      });
      return newState;
    });
  };

  const closeBtnHandler = () => {
    closeBtnClick(false);
  };

  const formSubmitHandler = () => {
    if (quality === "") {
      setQualityError(true);
    }
    if (description === "") {
      setDescriptionError(true);
    }
    if (quality === "Unacceptable" && comments === "") {
      setCommentsError(true);
    }
    const arr = servicesByContract.reduce((obj, prop) => {
      obj[prop.id] = validateService(prop);
      return obj;
    }, {});

    setErrors(arr);

    const arr2 = [];
    const keys = Object.keys(arr);
    for (let i = 0; i < keys.length; i++) {
      arr2.push(...Object.values(arr[keys[i]]));
    }

    if (arr2.includes(true)) {
      alertify.error("Something went wrong. Please check the form fields.");
    } else if (
      description === "" ||
      quality === "" ||
      (quality === "Unacceptable" && comments === "")
    ) {
      alertify.error("Something went wrong. Please check the form fields.");
    } else {
      setIsDisabled(true);
      extraStepFinished ? formSubmitProcessor() : setOpenModal(true);
    }
  };

  const formSubmitProcessor = () => {
    const data = {
      cabin_services_report: {
        date: service.flight_date,
        airline: service.airline,
        aircraft_type: service.aircraft_type,
        flight_in: service.flight_in,
        flight_out: service.flight_out,
        flight_eta: service.flight_eta,
        flight_etd: service.flight_etd,
        flight_gate: service.flight_gate,
        tail_number: service.flight_number,
        description: description,
        quality: quality,
        comments: comments,
        service_id: service.id,
        admin_user_id: adminId,

        contract_services_attributes: [
          ...servicesByContract.map((s) => {
            s.id = null;
            s.template = false;
            s.priority = 0;
            delete s.group_name;
            return s;
          }),
        ],
      },
    };

    if (isOnline) {
      axios
        .post("/admin/cabin_services_reports.json", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status == 201) {
            updateArrivalCards((prevState) => {
              const newState = prevState.map((s) => {
                if (s.id === service.id) {
                  s.report_cs = true;
                }
                return s;
              });
              return newState;
            });
            alertify.success("Report was successfully saved", 2, () => {
              updateService(service.id);
              setIsDisabled(false);
              closeBtnClick(false);
            });
          }
        })
        .catch((error) => {
          alertify.error("Something went wrong. Please try again.", 5);
        });
    } else {
      // if isOnline false
      let arr =
        localStorage.getItem("csreports") === null
          ? []
          : JSON.parse(localStorage.getItem("csreports"));
      arr.push(data);
      localStorage.setItem("csreports", JSON.stringify(arr));
      alertify.success("Report was successfully saved", 2, () => {
        updateService(service.id);
        setIsDisabled(false);
        closeBtnClick(false);
      });
    }
  };

  const validateTextField = (e) => {
    const { value } = e.target;
    const error = quality === "Unacceptable" && value === "";
    setCommentsError(error);
  };

  return (
    <Fragment>
      {!showForm && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      {showForm && (
        <Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <FormControl sx={{ m: 1, width: 140 }} size="small">
                <InputLabel id="add-service-label">Add Service</InputLabel>
                <Select
                  labelId="add-service-label"
                  id="add-service"
                  value={selectedService}
                  label="Add Service"
                  onChange={addServiceHandler}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {additionalServices.map((t) => (
                    <MenuItem value={t.id} key={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <IconButton onClick={closeBtnHandler}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ mb: "20px" }}>
            <Box flexGrow={1}>
              <h2 style={{ color: textColor, textAlign: "center" }}>
                Cabin Service Report
              </h2>
            </Box>
          </Box>
          <Grid container spacing={2} display="flex" justifyContent="center">
            <Grid item xs={12} md={4}>
              <List sx={listStyle}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <CalendarMonthIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Date"
                    secondary={formatDateUS(service.flight_date)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <AirlinesIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Airline" secondary={service.airline} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <FlightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Aircraft Type"
                    secondary={service.aircraft_type}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={6} md={4}>
              <List sx={listStyle}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <NumbersIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Flight"
                    secondary={service.flight_in}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <DoorSlidingIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Gate"
                    secondary={service.flight_gate}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <GroupsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Agent Qty" secondary={5} />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={6} md={4}>
              <List sx={listStyle}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <AccessTimeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="ETA" secondary={service.flight_eta} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <AccessTimeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="ETD" secondary={service.flight_etd} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={avatarStyle}>
                      <AppRegistrationIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Registration"
                    secondary={service.flight_number}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container sx={{ margin: "15px auto" }}>
            <img src="/images/aircraft-map.jpg" width="100%" />
          </Grid>

          {servicesByContract.map((service) => {
            const validationErrors = errors[service.id?.toString()] || {
              unit1: false,
              unit2: false,
              unit3: false,
              unit4: false,
              unit5: false,
              signer_name: false,
              start_loc: false,
              dest: false,
              start_time: false,
              end_time: false,
            };

            return (
              <div className="cserv-div" key={service.code}>
                <ContractService
                  setErrors={setErrors}
                  service={service}
                  errors={validationErrors}
                  onDelete={removeServiceHandler}
                  onChange={fieldChangeHandler}
                />
              </div>
            );
          })}
          <br />
          <OutlinedDiv label="Description & Quality">
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="left"
              sx={{ paddingBottom: "10px" }}
            >
              <Grid item xs={6} md={2}>
                <FormLabel id={"service-desc-control-buttons"}>
                  Description Of Service
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={10}>
                <RadioGroup
                  row
                  aria-labelledby={"service-desc-control-buttons"}
                  name="controlled-radio-buttons-group"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  id="sevice-desc"
                >
                  <FormControlLabel
                    value={"Full Cleaning"}
                    control={<Radio />}
                    label="Full Cleaning"
                  />
                  <FormControlLabel
                    value={"Light Cleaning"}
                    control={<Radio />}
                    label="Light Cleaning"
                  />
                </RadioGroup>
                {descriptionError && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {"Select one option"}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="left"
              sx={{ paddingBottom: "10px" }}
            >
              <Grid item xs={6} md={2}>
                <FormLabel id={"service-qlty-control-buttons"}>
                  The Quality Of Service
                </FormLabel>
              </Grid>
              <Grid item xs={6} md={10}>
                <RadioGroup
                  row
                  aria-labelledby={"service-qlty-control-buttons"}
                  name="controlled-radio-buttons-group"
                  value={quality}
                  onChange={(event) => setQuality(event.target.value)}
                  id="sevice-qlty"
                >
                  <FormControlLabel
                    value={"Acceptable"}
                    control={<Radio />}
                    label="Acceptable"
                  />
                  <FormControlLabel
                    value={"Unacceptable"}
                    control={<Radio />}
                    label="Unacceptable"
                  />
                </RadioGroup>
                {qualityError && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {"Select one option"}
                  </FormHelperText>
                )}
              </Grid>
              {commentsRequired && (
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    label="Comments"
                    placeholder="Comments"
                    sx={{ width: "100%" }}
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                    onBlur={validateTextField}
                    error={commentsError}
                    helperText={
                      commentsError ? "This field cannot be blank." : null
                    }
                    multiline
                  />
                </Grid>
              )}
            </Grid>
          </OutlinedDiv>

          {servicesByContract.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: "40px", mb: "30px" }}
            >
              <ButtonGroup
                size="large"
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  variant="contained"
                  color="info"
                  onClick={formSubmitHandler}
                  disabled={isDisabled}
                >
                  Submit Report
                </Button>
              </ButtonGroup>
            </Grid>
          )}
          <ReportModal
            open={openModal}
            setOpen={setOpenModal}
            setIsDisabled={setIsDisabled}
            extraServices={additionalServices}
            addExtraServices={addServices}
            formSubmitProcessor={formSubmitProcessor}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default CSReport;
