import React, { Fragment, useEffect, useState } from "react";
import SearchForm from "./ui/SearchForm";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import MultipleSelectCheckmarks from "./ui/MultipleSelectCheckmarks";
import { formatDateUS, numSec2time, getTime } from "../helpers";
import { Modal, Fade, Box, Button, ButtonGroup } from "@mui/material";
import { toHoursAndMinutes, capitalizeFirstLetter } from "../helpers";
import Backdrop from "@mui/material/Backdrop";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import CommentForm from "./GMR/CommentForm";
import GateChangeForm from "./GMR/GateChangeForm";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import i18n from "../i18n";
import { useTranslation, I18nextProvider } from "react-i18next";

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);

const styles = {
  customCell: { background: "#f3f6f7" },
  tableCell: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    position: "relative",
  },
  header: { fontWeight: "bold", background: "#f3f6f7" },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  },
};

const GMR = ({ airports, airlines, admin, can_comment, date }) => {
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(Array(5).fill(false));
  const [extraServices, setExtraServices] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedLine, setSelectedLine] = useState({});
  const [dateObject, setDateObject] = useState(date);
  const [cellName, setCellName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(admin.language);
    axios
      .get(`/admin/additional_service_forms/gmr_report?format=json`)
      .then((response) => {
        setExtraServices(response.data.extra_services);
        setData(convertTimeField(response.data.services));
        setDateObject(response.data.date);
        setEditMode(Array(response.data.services.length).fill(false));
        // setTemplates(response.data.templates);
      });
  }, []);

  const handleStatusCellClick = (index) => {
    setEditMode((prevState) => {
      const newEditMode = [...prevState];
      newEditMode[index] = !newEditMode[index];
      return newEditMode;
    });
  };

  const handleCheckboxChange = (index) => {
    axios
      .put(`/admin/registry_lines/${data[index].id}?format=json`, {
        registry_line: {
          status: data[index].status === "pending" ? "approved" : "pending",
        },
      })
      .then((response) => {
        setData((prevState) => {
          const newState = [...prevState];
          prevState[index].status =
            prevState[index].status === "pending" ? "approved" : "pending";
          return newState;
        });
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(`Checkbox at row ${index + 1} was clicked`);
  };

  const handleSaveRegistryLine = async () => {
    const updatedRegistryLine =
      cellName === "scheduled_time"
        ? {
            registry_line: {
              note: selectedLine.note,
              updated_by: selectedLine.updated_by,
              changed_scheduled_time: selectedLine.note
                ? selectedLine.changed_scheduled_time
                : null,
            },
          }
        : {
            registry_line: {
              gate: selectedLine.gate,
              updated_by: selectedLine.updated_by,
            },
          };
    const responseMessage =
      cellName === "scheduled_time"
        ? t("success_messages.comment")
        : t("success_messages.gate");
    const response = await axios.put(
      `/admin/registry_lines/${selectedLine.id}.json`,
      updatedRegistryLine
    );
    if (response.status === 204) {
      setData((prevState) => {
        const newState = prevState.map((el) => {
          return el.id !== selectedLine.id
            ? el
            : {
                ...selectedLine,
                changed_scheduled_time: selectedLine.note
                  ? selectedLine.changed_scheduled_time
                  : null,
              };
        });
        return newState;
      });
      setOpen(false);
      alertify.success(responseMessage);
    }
  };

  const handleCellClick = (id, cell_name, e) => {
    const selected = data.find((item) => item.id === id);
    setCellName(cell_name);
    setSelectedLine(selected);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const convertTimeField = (data) => {
    return data.map((row) => {
      if (row.changed_scheduled_time) {
        row.changed_scheduled_time = toHoursAndMinutes(
          row.changed_scheduled_time
        );
      }
      return row;
    });
  };

  const getReport = (
    date_from = "",
    date_to = "",
    airport = "",
    airline = ""
  ) => {
    axios
      .get(
        `/admin/additional_service_forms/gmr_report?date_from=${date_from}&date_to=${date_to}&airport=${airport}&airline=${airline}&format=json`
      )
      .then((response) => {
        setExtraServices(response.data.extra_services);
        setData(convertTimeField(response.data.services));
        setDateObject(response.data.date);
      });
  };

  const getLink = (
    date_from = "",
    date_to = "",
    airport = "",
    airline = ""
  ) => {
    return `/additional_service_forms/gmr_report?date_from=${date_from}&date_to=${date_to}&airport=${airport}&airline=${airline}&format=html`;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const hasToReview = data.filter((el) => el.reviewer !== null).length > 0;

  return (
    <I18nextProvider i18n={i18n}>
      <Modal
        aria-labelledby="new-comment-modal"
        aria-describedby="new-comment-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...styles.modal }}>
            {cellName === "scheduled_time" && (
              <CommentForm
                selectedLine={selectedLine}
                setSelectedLine={setSelectedLine}
                admin={admin}
                locale={t}
              />
            )}
            {cellName === "gate" && (
              <GateChangeForm
                selectedLine={selectedLine}
                setSelectedLine={setSelectedLine}
                admin={admin}
                locale={t}
              />
            )}
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
            >
              <Button
                size="large"
                color="info"
                variant="contained"
                onClick={handleSaveRegistryLine}
                sx={{ width: "168px" }}
              >
                {t("save")}
              </Button>
              <Button onClick={() => setOpen(false)} sx={{ width: "168px" }}>
                {t("cancel")}
              </Button>
            </ButtonGroup>
          </Box>
        </Fade>
      </Modal>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <SearchForm
          locale={t}
          admin={admin}
          setData={getReport}
          getLink={getLink}
          airports={airports}
          airlines={airlines}
          showAirlines={true}
          date={dateObject}
          downloadable={!(templates.length > 0)}
        />
        <TableContainer sx={{ mb: 2, maxHeight: 800 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-label="sticky table"
            id="gmr_report"
          >
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 10,
              }}
            >
              <TableRow>
                <TableCell colSpan={2} sx={{ ...styles.customCell }}>
                  <div
                    style={{
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                    }}
                  >
                    <div className="ad_status approved">{t("approved")}</div>
                    <div className="ad_status declined">{t("declined")}</div>
                    <div className="ad_status pending">{t("pending")}</div>
                  </div>
                </TableCell>
                <TableCell colSpan={3} sx={{ ...styles.customCell }}>
                  <MultipleSelectCheckmarks
                    setTemplates={setTemplates}
                    locale={t}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.customCell }}
                  colSpan={11}
                ></TableCell>
                {templates.map((el) => {
                  let colSpan = 2;
                  if (
                    (el.enable_start_end_time && !el.enable_yes_no) ||
                    el.enable_start_loc_dest ||
                    (el.enable_unit && !el.enable_yes_no)
                  ) {
                    colSpan = 3;
                  }
                  return (
                    <Fragment key={`h${el.id}`}>
                      <TableCell
                        align="center"
                        sx={{
                          ...styles.tableCell,
                          ...styles.header,
                          ...styles.customCell,
                        }}
                        colSpan={colSpan}
                        key={el.name}
                      >
                        {el.name.toUpperCase()}
                      </TableCell>
                    </Fragment>
                  );
                })}
              </TableRow>
              <TableRow>
                {(["station_manager", "system_admin"].includes(admin.role) ||
                  hasToReview) && (
                  <TableCell
                    align="center"
                    sx={{ ...styles.tableCell, ...styles.header }}
                  >
                    {t("status")}
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("code")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("date")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  AD
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("airline")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("flight_number")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("aircraft_type")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("reg_num")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("sta")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("ata")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("note")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("gate")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("first_bag")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("last_bag")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("first_cargo")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.tableCell, ...styles.header }}
                >
                  {t("last_cargo")}
                </TableCell>
                {templates.map((el, index) => {
                  if (el.enable_yes_no) {
                    return (
                      <Fragment key={index}>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          Y/N
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          ASF #
                        </TableCell>
                      </Fragment>
                    );
                  } else if (el.enable_start_end_time && !el.enable_yes_no) {
                    return (
                      <Fragment key={index}>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          {el.label_start_time}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          {el.label_end_time}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          ASF #
                        </TableCell>
                      </Fragment>
                    );
                  } else if (el.enable_unit && !el.enable_yes_no) {
                    return (
                      <Fragment key={index}>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          {el.label_unit1}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          {el.label_unit2}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          ASF #
                        </TableCell>
                      </Fragment>
                    );
                  } else if (el.enable_start_loc_dest) {
                    return (
                      <Fragment key={index}>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          {el.label_start_loc}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          {el.label_dest}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCell, ...styles.header }}
                        >
                          ASF #
                        </TableCell>
                      </Fragment>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => {
                const chars = row.flight_number.slice(
                  0,
                  row.flight_number.search(/\d/)
                );
                const numbs = row.flight_number.replace(chars, "");
                return (
                  <TableRow
                    key={`row_${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {(["station_manager", "system_admin"].includes(
                      admin.role
                    ) ||
                      hasToReview) && (
                      <TableCell
                        align="center"
                        sx={styles.tableCell}
                        onClick={() => handleStatusCellClick(index)}
                      >
                        {(["station_manager", "system_admin"].includes(
                          admin.role
                        ) ||
                          row.reviewer === admin.id) &&
                          (editMode[index] ? (
                            <input
                              type="checkbox"
                              checked={row.status === "approved"}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          ) : row.status === "approved" ? (
                            <CheckIcon color="success" />
                          ) : (
                            <CloseIcon color="error" />
                          ))}
                      </TableCell>
                    )}
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.tail_number}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {formatDateUS(row.date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        background:
                          row.ad_type === "Departure"
                            ? "rgb(197,217,241)"
                            : "rgb(196,215,155)",
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        fontSize: 12,
                      }}
                    >
                      {row.ad_type.toUpperCase()}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {chars}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {numbs}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.ac_type}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.tail_number}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={styles.tableCell}
                      className={row.note ? "comment pointer" : "pointer"}
                      onClick={
                        can_comment
                          ? handleCellClick.bind(this, row.id, "scheduled_time")
                          : null
                      }
                    >
                      <Tooltip title={row.note} arrow>
                        {numSec2time(row.scheduled_time)}
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.actual_time ? numSec2time(row.actual_time) : "-"}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.cancelled
                        ? "Cancelled"
                        : row.diverted
                        ? "Diverted Flight"
                        : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="pointer"
                      sx={styles.tableCell}
                      onClick={
                        can_comment
                          ? handleCellClick.bind(this, row.id, "gate")
                          : null
                      }
                    >
                      {row.gate}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.first_bag}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.last_bag}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.first_cargo}
                    </TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      {row.last_cargo}
                    </TableCell>
                    {templates.map((el, index) => {
                      let colSpan = 2;
                      if (
                        (el.enable_start_end_time && !el.enable_yes_no) ||
                        el.enable_start_loc_dest
                      ) {
                        colSpan = 3;
                      }
                      let keys = Object.keys(extraServices);

                      if (el.enable_yes_no) {
                        return (
                          <Fragment key={`tr_${index}`}>
                            <TableCell align="center" sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return "Y";
                                }
                              })}
                            </TableCell>
                            <TableCell align="center" sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return (
                                    <div
                                      className={`ads_status ${extraServices[key][0].status}`}
                                      key={`trl${extraServices[key][0].trl_id}`}
                                    >
                                      <a
                                        href={`/additional_service_forms/${extraServices[key][0].slug}/show_pdf`}
                                        target="_blank"
                                      >
                                        {`# ${extraServices[key][0].trl_id}`}{" "}
                                        <br />
                                      </a>
                                    </div>
                                  );
                                }
                              })}
                            </TableCell>
                          </Fragment>
                        );
                      } else if (
                        el.enable_start_end_time &&
                        !el.enable_yes_no
                      ) {
                        return (
                          <Fragment key={`tr_${index}`}>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return extraServices[key].map((s) => {
                                    return (
                                      <Fragment key={`st${s.id}`}>
                                        {getTime(s.start_time)} <br />
                                      </Fragment>
                                    );
                                  });
                                }
                              })}
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return extraServices[key].map((s) => {
                                    return (
                                      <Fragment key={`et${s.id}`}>
                                        {getTime(s.end_time)} <br />
                                      </Fragment>
                                    );
                                  });
                                }
                              })}
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return (
                                    <div
                                      className={`ads_status ${extraServices[key][0].status}`}
                                      key={`trl${extraServices[key][0].trl_id}`}
                                    >
                                      <a
                                        href={`/additional_service_forms/${extraServices[key][0].slug}/show_pdf`}
                                        target="_blank"
                                      >
                                        {`# ${extraServices[key][0].trl_id}`}{" "}
                                        <br />
                                      </a>
                                    </div>
                                  );
                                }
                              })}
                            </TableCell>
                          </Fragment>
                        );
                      } else if (el.enable_start_loc_dest) {
                        return (
                          <Fragment key={`tr_${index}`}>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return extraServices[key].map((s) => {
                                    return (
                                      <Fragment key={`st${s.id}`}>
                                        {s.start_loc} <br />
                                      </Fragment>
                                    );
                                  });
                                }
                              })}
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return extraServices[key].map((s) => {
                                    return (
                                      <Fragment key={`st${s.id}`}>
                                        {s.dest} <br />
                                      </Fragment>
                                    );
                                  });
                                }
                              })}
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return (
                                    <div
                                      className={`ads_status ${extraServices[key][0].status}`}
                                      key={`trl${extraServices[key][0].trl_id}`}
                                    >
                                      <a
                                        href={`/additional_service_forms/${extraServices[key][0].slug}/show_pdf`}
                                        target="_blank"
                                      >
                                        {`# ${extraServices[key][0].trl_id}`}{" "}
                                        <br />
                                      </a>
                                    </div>
                                  );
                                }
                              })}
                            </TableCell>
                          </Fragment>
                        );
                      } else if (el.enable_unit && !el.enable_yes_no) {
                        return (
                          <Fragment key={`tr_${index}`}>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return extraServices[key].map((s) => {
                                    return (
                                      <Fragment key={`st${s.id}`}>
                                        {s.unit1} <br />
                                      </Fragment>
                                    );
                                  });
                                }
                              })}
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return extraServices[key].map((s) => {
                                    return (
                                      <Fragment key={`st${s.id}`}>
                                        {s.unit2} <br />
                                      </Fragment>
                                    );
                                  });
                                }
                              })}
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              {keys.map((key) => {
                                if (
                                  key.includes(row.date) &&
                                  key.includes(row.flight_number) &&
                                  key.includes(el.name)
                                ) {
                                  return (
                                    <div
                                      className={`ads_status ${extraServices[key][0].status}`}
                                      key={`trl${extraServices[key][0].trl_id}`}
                                    >
                                      <a
                                        href={`/additional_service_forms/${extraServices[key][0].slug}/show_pdf`}
                                        target="_blank"
                                      >
                                        {`# ${extraServices[key][0].trl_id}`}{" "}
                                        <br />
                                      </a>
                                    </div>
                                  );
                                }
                              })}
                            </TableCell>
                          </Fragment>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
              {data.length <= 0 && (
                <TableRow>
                  <TableCell align="center" sx={styles.tableCell} colSpan={15}>
                    <i>NO DATA</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 75, 100, 150, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </I18nextProvider>
  );
};

export default GMR;
