import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import UpdateIcon from "@mui/icons-material/Update";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const SearchForm = ({ airports }) => {
  const [requestType, setRequestType] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [airport, setAirport] = useState("");
  const [airline, setAirline] = useState("");
  const [airlines, setAirlines] = useState([]);
  const [searchDate, setSearchDate] = useState(
    dayjs(
      localStorage.getItem(`request_date`) ||
        new Date().toISOString().split("T")[0]
    )
  );

  useEffect(() => {
    fetch("/admin/flights/airlines?format=json").then((response) => {
      return response.json().then((data) => {
        setAirlines(
          data
            .filter(
              (el) =>
                el.icao != undefined &&
                el.icao.length >= 3 &&
                el.icao != "N/A" &&
                /[a-zA-Z]/g.test(el.icao)
            )
            .map((el) => el.icao)
            .filter((item, i, ar) => ar.indexOf(item) === i)
        );
      });
    });
  }, []);

  const buttonClickHandler = () => {};

  const options = airlines.map((option) => {
    const firstLetter = option[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      label: option,
    };
  });

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#eee",
        padding: "20px",
        borderRadius: "3px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
    >
      <Grid item sm={6} md={4} lg={3}>
        <InputLabel
          sx={{
            fontWeight: 500,
          }}
        >
          Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={searchDate}
            onChange={(newValue) => setSearchDate(newValue)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item sm={6} md={4} lg={3}>
        <InputLabel
          sx={{
            fontWeight: 500,
          }}
        >
          Type
        </InputLabel>
        <Select
          sx={{ minWidth: 222 }}
          labelId="type-label"
          id="type"
          value={requestType}
          onChange={(e) => setRequestType(e.target.value)}
        >
          <MenuItem value={"destination"}>Arrivals</MenuItem>
          <MenuItem value={"origin"}>Departures</MenuItem>
        </Select>
      </Grid>
      <Grid item sm={6} md={4} lg={3}>
        <InputLabel
          sx={{
            fontWeight: 500,
          }}
        >
          Airport
        </InputLabel>
        <Select
          sx={{ width: 222 }}
          labelId="airport-label"
          id="airport"
          value={airport}
          onChange={(e) => setAirport(e.target.value)}
        >
          {airports.map((option) => {
            return (
              <MenuItem value={option.iata} key={option.iata}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item sm={6} md={4} lg={3}>
        <InputLabel
          sx={{
            fontWeight: 500,
          }}
        >
          Airline
        </InputLabel>
        <Autocomplete
          disablePortal
          id="airline"
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          inputValue={airline}
          onInputChange={(event, newInputValue) => {
            setAirline(newInputValue);
          }}
          sx={{ width: 222 }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Type to search airline" />
          )}
        />
      </Grid>

      <Grid item sm={6} md={4} lg={3} sx={{ paddingTop: 3 }}>
        <Button
          variant="contained"
          onClick={buttonClickHandler}
          startIcon={<UpdateIcon />}
          size="large"
          sx={{ width: 222 }}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchForm;
