export const equipmentOptions = [
  {label: "TUG", value: "TUG"},
  {label: "BELTLOAD", value: "BELTLOAD"},
  {label: "CN-014", value: "CN-014"},
  {label: "CN-05", value: "CN-05"},
  {label: "CN-10", value: "CN-10"},
  {label: "CN-202", value: "CN-202"},
  {label: "CN-27", value: "CN-27"},
  {label: "CN-53", value: "CN-53"},
  {label: "CN-54", value: "CN-54"},
  {label: "DT-75", value: "DT-75"},
  {label: "DT-91", value: "DT-91"},
  {label: "DT-92", value: "DT-92"},
  {label: "DT-93", value: "DT-93"},
  {label: "DT-95", value: "DT-95"},
  {label: "M-017", value: "M-017"},
  {label: "M-30", value: "M-30"},
  {label: "M-31", value: "M-31"},
  {label: "T-006", value: "T-006"},
  {label: "T-018", value: "T-018"},
  {label: "T-030", value: "T-030"}]

export const inopEquipmentOptions = [
  {label: "BELTLOAD", value: "BELTLOAD"},
  {label: "A/C", value: "A/C"},
  {label: "AT-11", value: "AT-11"},
  {label: "BLUE TRUCK", value: "BLUE TRUCK"},
  {label: "CN-07", value: "CN-07"},
  {label: "COMMANDER", value: "COMMANDER"},
  {label: "DT-96", value: "DT-96"},
  {label: "LO-61", value: "LO-61"},
  {label: "M-019", value: "M-019"},
  {label: "M-21", value: "M-21"},
  {label: "M-35", value: "M-35"},
  {label: "M-36", value: "M-36"},
  {label: "MET-10", value: "MET-10"},
  {label: "PAYMOVER", value: "PAYMOVER"},
  {label: "T-012", value: "T-012"},
  {label: "TUG", value: "TUG"},
  {label: "TUG ELECT", value: "TUG ELECT"}
]