import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, Paper } from "@mui/material";
import { formatDateUS } from "../helpers";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { red, green } from "@mui/material/colors";

const UncompletedServices = ({ uncompleted_services }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "date", label: "Flight Date", minWidth: 120, align: "center" },
    { id: "type", label: "Flight Type", minWidth: 30, align: "center" },
    { id: "flight_number", label: "Flight #", minWidth: 60, align: "center" },
    {
      id: "rsra_forms_count",
      label: "RSRA Form",
      minWidth: 60,
      align: "center",
    },
    { id: "ramp_report", label: "RFO Report", minWidth: 60, align: "center" },
    { id: "link", label: "Actions", minWidth: 60, align: "center" },
  ];

  const createData = (
    date,
    type,
    flight_number,
    rsra_forms_count,
    ramp_report,
    link
  ) => {
    return { date, type, flight_number, rsra_forms_count, ramp_report, link };
  };

  const rows = uncompleted_services.map((flight) =>
    createData(
      flight.date,
      flight.arrival ? "Arrival" : "Departure",
      flight.flight_number,
      flight.rsra_forms_count,
      flight.ramp_report,
      flight.date
    )
  );
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 540 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    color: "#050505",
                    fontWeight: 600,
                    borderBottomColor: "#e4eaec",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody id="uncompleted_services">
            {rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id + row.type}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ borderBottomColor: "#e4eaec" }}
                          >
                            {column.id === "date" && formatDateUS(value)}
                            {["type", "flight_number"].includes(column.id) &&
                              value}
                            {column.id === "rsra_forms_count" &&
                              row["type"] === "Arrival" &&
                              (value === 0 ? (
                                <CheckBoxOutlineBlankIcon
                                  style={{ color: red[500] }}
                                />
                              ) : (
                                <CheckBoxIcon style={{ color: green[500] }} />
                              ))}
                            {column.id === "ramp_report" &&
                              (value ? (
                                <CheckBoxIcon style={{ color: green[500] }} />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              ))}
                            {column.id === "link" && (
                              <Link
                                href={`/admin/dashboard?date=${row["date"]}`}
                              >
                                See More
                              </Link>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}

            {rows.length == 0 && (
              <TableRow role="checkbox" tabIndex={-1}>
                <TableCell
                  key="1"
                  align="center"
                  colSpan={6}
                  sx={{ borderBottomColor: "#e4eaec", color: "#050505" }}
                >
                  <i>YOU HAVE 0 UNCOMPLETED SERVICES</i>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default UncompletedServices;
