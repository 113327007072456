import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const CommentForm = ({
  open,
  handleClose,
  service,
  saveServiceHandler,
  setService,
  locale,
}) => {
  const saveBtnClickHandler = () => {
    saveServiceHandler(service.id, service.comment);
  };

  const commentChangeHandler = (e) => {
    setService((prevState) => {
      return {
        ...prevState,
        comment: e.target.value,
      };
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {service.comment ? locale("edit_comment") : locale("add_comment")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{locale("helper_text.comment")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            type="text"
            multiline
            maxRows={4}
            fullWidth
            variant="filled"
            value={service.comment}
            onChange={commentChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{locale("cancel")}</Button>
          <Button onClick={saveBtnClickHandler}>{locale("save")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommentForm;
