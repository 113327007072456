import React, { Fragment, useEffect, useState } from "react";
import {
  TextField,
  FormLabel,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import SignatureModal from "../ui/SignatureModal";
import { formatDateUS } from "../../helpers";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import axios from "axios";

// Setting up the token for HTTP requests
const token = document.querySelector('[name="csrf-token"]').content;
const textNormal = { fontWeight: "normal", color: "#000" };
const marginBottom20 = { marginBottom: "20px" };
axios.defaults.headers.common["X-CSRF-Token"] = token;
// Updating settings for alert box
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);

const styles = {
  legend: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    color: "#00bbf2",
    fontWeight: 600,
  },
};

const useStyles = makeStyles(() => ({
  inputMultiline: {
    "& .MuiInputBase-input": {
      height: "200px",
    },
  },
}));

const initialForm = {};
for (let i = 1; i <= 44; i++) {
  initialForm[`q_${i}`] = null;
}

const RSForm = ({
  service,
  closeBtnClick,
  isOnline,
  updateFormsCount,
  adminId,
  locale,
}) => {
  const [form, setForm] = useState(initialForm);
  const [showForm, setShowForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [signBtnDisabled, setSignBtnDisabled] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    let localForm = localStorage.getItem(`rs_form_${service.id}`);
    localForm = localForm ? JSON.parse(localForm) : initialForm;
    setForm(localForm);
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 440);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  // Form submit button disable/enable
  useEffect(() => {
    setSubmitBtnDisabled(imageURL === null);
  }, [imageURL, setImageURL]);

  // Note text field and create signature button disable/enable
  useEffect(() => {
    setShowNoteInput(Object.values(form).includes("No"));
    setSignBtnDisabled(Object.values(form).includes(null));
  }, [form, setForm]);

  const closeBtnHandler = () => {
    localStorage.setItem(`rs_form_${service.id}`, JSON.stringify(form));
    closeBtnClick(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const { id } = e.target.parentElement.parentElement.parentElement;
    setForm({ ...form, [id]: value });
  };

  const handleYesToAllClick = (num1, num2) => {
    let formNewValues = {};
    for (let i = num1; i <= num2; i++) {
      formNewValues[`q_${i}`] = "Yes";
    }
    setForm({ ...form, ...formNewValues });
  };

  const formSubmitHandler = () => {
    const data = {
      op201026_form: {
        ...form,
        flight: service.flight_in,
        tail_number: service.flight_number,
        sta: service.flight_sta,
        date: service.flight_date,
        note: "",
        signature: imageURL,
        service_id: service.id,
        admin_user_id: adminId,
      },
    };
    if (isOnline) {
      setSignBtnDisabled(true);
      setSubmitBtnDisabled(true);
      axios
        .post("/admin/op201026_forms.json", data)
        .then((response) => {
          if (response.status === 201) {
            localStorage.removeItem(`rs_form_${service.id}`);
            alertify.success(
              locale("success_messages.form_submitted"),
              2,
              () => {
                updateFormsCount(service.id);
                closeBtnClick(false);
              }
            );
          }
        })
        .catch((error) => {
          alertify.error(locale("error_messages.main"), 5);
        });
    } else {
      let arr =
        localStorage.getItem("rsforms") === null
          ? []
          : JSON.parse(localStorage.getItem("rsforms"));
      arr.push(data);
      localStorage.removeItem(`rs_form_${service.id}`);
      localStorage.setItem("rsforms", JSON.stringify(arr));
      alertify.success(locale("success_messages.form_submitted"), 2, () => {
        updateFormsCount(service.id);
        closeBtnClick(false);
      });
    }
  };

  return (
    <Fragment>
      {openModal && (
        <SignatureModal
          setOpenModal={setOpenModal}
          setImageURL={setImageURL}
          locale={locale}
        />
      )}
      <Box component="div" sx={{ p: 2 }}>
        {!showForm && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="primary" />
          </Box>
        )}
        {showForm && (
          <form>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <h2 style={{ textAlign: "center", color: "#000000" }}>
                  {locale("ramp_checklist")}
                </h2>
              </Box>
              <Box>
                <IconButton onClick={closeBtnHandler}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <br />
            <Grid container spacing={2}>
              <Grid item sm={6} md={3}>
                <h3 style={textNormal}>
                  <strong>{locale("flight_number")}: </strong>
                  {service.flight_in}
                </h3>
              </Grid>
              <Grid item sm={6} md={3}>
                <h3 style={textNormal}>
                  <strong>{locale("tail_number")}: </strong>
                  {service.flight_number}
                </h3>
              </Grid>
              <Grid item sm={6} md={3}>
                <h3 style={textNormal}>
                  <strong>{locale("sta")}: </strong>
                  {service.flight_sta}
                </h3>
              </Grid>
              <Grid item sm={6} md={3}>
                <h3 style={textNormal}>
                  <strong>{locale("date")}: </strong>
                  {formatDateUS(service.flight_date)}
                </h3>
              </Grid>
            </Grid>
            <br />
            <Box component="fieldset" style={marginBottom20}>
              <legend style={styles.legend}>
                <span>{locale("crew_briefing").toUpperCase()}</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleYesToAllClick.bind(this, 1, 13)}
                >
                  {locale("yes_to_all")}
                </Button>
              </legend>

              <Grid container spacing={2}>
                {[
                  locale("attributes.op201026_form.q_1"),
                  locale("attributes.op201026_form.q_2"),
                  locale("attributes.op201026_form.q_3"),
                  locale("attributes.op201026_form.q_4"),
                ].map((label, index) => {
                  const fieldNumber = index + 1;
                  return (
                    <Fragment key={`q${fieldNumber}`}>
                      <Grid item sm={6} md={8}>
                        <FormLabel
                          id={`q${fieldNumber}-controlled-radio-buttons-group`}
                        >
                          {label}
                        </FormLabel>
                      </Grid>
                      <Grid item sm={6} md={4}>
                        <RadioGroup
                          row
                          aria-labelledby={`q${fieldNumber}-controlled-radio-buttons-group`}
                          name="controlled-radio-buttons-group"
                          onChange={handleChange}
                          value={form[`q_${fieldNumber}`]}
                          id={`q_${fieldNumber}`}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label={locale("yes")}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label={locale("no")}
                          />
                        </RadioGroup>
                      </Grid>
                    </Fragment>
                  );
                })}
                <Grid item xs={12} sm={12}>
                  <p>{locale("safety_topics")}: </p>
                </Grid>
                {[
                  locale("attributes.op201026_form.q_5"),
                  locale("attributes.op201026_form.q_6"),
                  locale("attributes.op201026_form.q_7"),
                  locale("attributes.op201026_form.q_8"),
                  locale("attributes.op201026_form.q_9"),
                  locale("attributes.op201026_form.q_10"),
                  locale("attributes.op201026_form.q_11"),
                  locale("attributes.op201026_form.q_12"),
                  locale("attributes.op201026_form.q_13"),
                ].map((label, index) => {
                  const fieldNumber = index + 5;
                  return (
                    <Fragment key={`q${fieldNumber}`}>
                      <Grid item sm={6} md={8}>
                        <FormLabel
                          id={`q${fieldNumber}-controlled-radio-buttons-group`}
                        >
                          {label}
                        </FormLabel>
                      </Grid>
                      <Grid item sm={6} md={4}>
                        <RadioGroup
                          row
                          aria-labelledby={`q${fieldNumber}-controlled-radio-buttons-group`}
                          name="controlled-radio-buttons-group"
                          onChange={handleChange}
                          value={form[`q_${fieldNumber}`]}
                          id={`q_${fieldNumber}`}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label={locale("yes")}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label={locale("no")}
                          />
                        </RadioGroup>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
            <Box component="fieldset" style={marginBottom20}>
              <legend style={styles.legend}>
                <span>{locale("equipment_inspection").toUpperCase()}</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleYesToAllClick.bind(this, 14, 27)}
                >
                  {locale("yes_to_all")}
                </Button>
              </legend>
              <Grid container spacing={2}>
                {[
                  locale("attributes.op201026_form.q_14"),
                  locale("attributes.op201026_form.q_15"),
                  locale("attributes.op201026_form.q_16"),
                  locale("attributes.op201026_form.q_17"),
                  locale("attributes.op201026_form.q_18"),
                  locale("attributes.op201026_form.q_19"),
                  locale("attributes.op201026_form.q_20"),
                  locale("attributes.op201026_form.q_21"),
                  locale("attributes.op201026_form.q_22"),
                  locale("attributes.op201026_form.q_23"),
                  locale("attributes.op201026_form.q_24"),
                  locale("attributes.op201026_form.q_25"),
                  locale("attributes.op201026_form.q_26"),
                  locale("attributes.op201026_form.q_27"),
                ].map((label, index) => {
                  const fieldNumber = index + 14;
                  return (
                    <Fragment key={`q${fieldNumber}`}>
                      <Grid item sm={12} md={4}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} md={8}>
                            <FormLabel
                              id={`q${fieldNumber}-controlled-radio-buttons-group`}
                            >
                              {label}
                            </FormLabel>
                          </Grid>
                          <Grid item sm={6} md={4}>
                            <RadioGroup
                              row
                              aria-labelledby={`q${fieldNumber}-controlled-radio-buttons-group`}
                              name="controlled-radio-buttons-group"
                              onChange={handleChange}
                              value={form[`q_${fieldNumber}`]}
                              id={`q_${fieldNumber}`}
                            >
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label={locale("yes")}
                              />
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label={locale("no")}
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
            <Box component="fieldset" style={marginBottom20}>
              <legend style={styles.legend}>
                <span>AIRCRAFT ARRIVAL</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleYesToAllClick.bind(this, 28, 36)}
                >
                  {locale("yes_to_all")}
                </Button>
              </legend>
              <Grid container spacing={2}>
                {[
                  locale("attributes.op201026_form.q_28"),
                  locale("attributes.op201026_form.q_29"),
                  locale("attributes.op201026_form.q_30"),
                  locale("attributes.op201026_form.q_31"),
                  locale("attributes.op201026_form.q_32"),
                  locale("attributes.op201026_form.q_33"),
                  locale("attributes.op201026_form.q_34"),
                  locale("attributes.op201026_form.q_35"),
                  locale("attributes.op201026_form.q_36"),
                ].map((label, index) => {
                  const fieldNumber = index + 28;
                  return (
                    <Fragment key={`q${fieldNumber}`}>
                      <Grid item sm={6} md={8}>
                        <FormLabel
                          id={`q${fieldNumber}-controlled-radio-buttons-group`}
                        >
                          {label}
                        </FormLabel>
                      </Grid>
                      <Grid item sm={6} md={4}>
                        <RadioGroup
                          row
                          aria-labelledby={`q${fieldNumber}-controlled-radio-buttons-group`}
                          name="controlled-radio-buttons-group"
                          onChange={handleChange}
                          value={form[`q_${fieldNumber}`]}
                          id={`q_${fieldNumber}`}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label={locale("yes")}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label={locale("no")}
                          />
                        </RadioGroup>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
            <Box component="fieldset" style={marginBottom20}>
              <legend style={styles.legend}>
                <span>{locale("aoa_operations").toUpperCase()}</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleYesToAllClick.bind(this, 37, 44)}
                >
                  {locale("yes_to_all")}
                </Button>
              </legend>
              <Grid container spacing={2}>
                {[
                  locale("attributes.op201026_form.q_37"),
                  locale("attributes.op201026_form.q_38"),
                  locale("attributes.op201026_form.q_39"),
                  locale("attributes.op201026_form.q_40"),
                  locale("attributes.op201026_form.q_41"),
                  locale("attributes.op201026_form.q_42"),
                  locale("attributes.op201026_form.q_43"),
                  locale("attributes.op201026_form.q_44"),
                ].map((label, index) => {
                  const fieldNumber = index + 37;
                  return (
                    <Fragment key={`q${fieldNumber}`}>
                      <Grid item sm={6} md={8}>
                        <FormLabel
                          id={`q${fieldNumber}-controlled-radio-buttons-group`}
                        >
                          {label}
                        </FormLabel>
                      </Grid>
                      <Grid item sm={6} md={4}>
                        <RadioGroup
                          row
                          aria-labelledby={`q${fieldNumber}-controlled-radio-buttons-group`}
                          name="controlled-radio-buttons-group"
                          onChange={handleChange}
                          value={form[`q_${fieldNumber}`]}
                          id={`q_${fieldNumber}`}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label={locale("yes")}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label={locale("no")}
                          />
                        </RadioGroup>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
            <br />
            <Box component="fieldset" style={marginBottom20}>
              {showNoteInput && (
                <TextField
                  fullWidth
                  label="Note"
                  id="note"
                  variant="filled"
                  multiline
                  row={1}
                  className={classes.inputMultiline}
                />
              )}
            </Box>
            <br />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonGroup
                size="large"
                variant="contained"
                aria-label="contained button group"
              >
                <Button
                  onClick={() => setOpenModal(true)}
                  disabled={signBtnDisabled}
                >
                  {locale("create_signature")}
                </Button>
                <Button
                  color="primary"
                  onClick={formSubmitHandler}
                  disabled={submitBtnDisabled}
                >
                  {locale("submit")}
                </Button>
              </ButtonGroup>
            </Grid>
          </form>
        )}
      </Box>
    </Fragment>
  );
};

export default RSForm;
