import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const SearchForm = ({
  locale,
  setData,
  getLink,
  airports,
  airlines,
  showAirlines,
  date,
  admin,
}) => {
  const [dateFrom, setDateFrom] = useState(dayjs(date.start));
  const [dateTo, setDateTo] = useState(dayjs(date.end));
  const [selectedAirport, setSelectedAirport] = useState(
    admin.airports[admin.airports.length - 1]
  );
  const [selectedAirline, setSelectedAirline] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (dateFrom.format("YYYY-MM-DD") <= dateTo.format("YYYY-MM-DD")) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [dateFrom, setDateFrom, dateTo, setDateTo]);

  const clickSearchHandler = (e) => {
    setData(
      dateFrom.format("YYYY-MM-DD"),
      dateTo.format("YYYY-MM-DD"),
      selectedAirport,
      selectedAirline
    );
  };

  const href = getLink(
    dateFrom.format("YYYY-MM-DD"),
    dateTo.format("YYYY-MM-DD"),
    selectedAirport,
    selectedAirline
  );

  return (
    <div className="columns">
      <div className="column" style={{ margin: "10px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={locale("date_from")}
            value={dateFrom}
            onChange={(newValue) => setDateFrom(newValue)}
          />
        </LocalizationProvider>
      </div>
      <div className="column" style={{ margin: "10px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={locale("date_to")}
            value={dateTo}
            onChange={(newValue) => setDateTo(newValue)}
            slotProps={{
              textField: {
                helperText: locale("helper_text.greater_date_from"),
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="column">
        <FormControl sx={{ m: 1, width: "222px" }}>
          <InputLabel id="select-airport-label">{locale("airport")}</InputLabel>
          <Select
            labelId="select-airport-label"
            id="select-airport"
            label="Airport"
            value={selectedAirport}
            onChange={(e) => setSelectedAirport(e.target.value)}
          >
            <MenuItem value={""} key={"all"}>
              All
            </MenuItem>
            {airports.map((option, i) => (
              <MenuItem value={option.id} key={option.id}>
                {option.iata}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {showAirlines && (
        <div className="column">
          <FormControl sx={{ m: 1, width: "222px" }}>
            <InputLabel id="select-airline-label">
              {locale("airline")}
            </InputLabel>
            <Select
              labelId="select-airline-label"
              id="select-airline"
              label="Airline"
              value={selectedAirline}
              onChange={(e) => setSelectedAirline(e.target.value)}
            >
              <MenuItem value={""} key={"all"}>
                All
              </MenuItem>
              {airlines.map((option, i) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="column" style={{ margin: "14px 10px 10px" }}>
        <ButtonGroup variant="contained" aria-label="button group">
          <Button
            size="large"
            color="info"
            onClick={clickSearchHandler}
            disabled={disabled}
            startIcon={<SearchIcon />}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            {locale("search")}
          </Button>
          <Button
            size="large"
            color="secondary"
            startIcon={<CloudDownloadIcon />}
            disabled={disabled}
            href={href}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            {locale("download")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default SearchForm;
