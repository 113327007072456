import React, { useState, useEffect, Fragment } from "react";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import axios from "axios";
import { Box } from "@mui/material";

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;

const TimelineComponent = ({ tabName }) => {
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`/admin/groups/by_airport?tab_name=${tabName}`)
      .then((response) => response.json())
      .then((data) => {
        const newGroups = data.map((el) => {
          return {
            id: el.id,
            title: el.name,
          };
        });
        setGroups(newGroups);
      });
    fetch(`/admin/schedules/formatted?tab_name=${tabName}`)
      .then((response) => response.json())
      .then((data) => {
        const newItems = data.schedules.map((s) => {
          return {
            id: s.id,
            group: s.group,
            title: s.title,
            start_time: moment(new Date(s.start_time)),
            end_time: moment(new Date(s.end_time)),
            itemProps: {
              onDoubleClick: () => {
                window.location.href = `/admin/schedules/${s.id}`;
              },
              style: {
                background: s.color,
              },
            },
          };
        });
        setItems(newItems);
      });
  }, []);

  const onItemDragHandler = ({ eventType, itemId, time, newGroupOrder }) => {
    const movedItem = items.filter((i) => i.id === itemId)[0];
    const duration = moment.duration(
      movedItem.end_time.diff(movedItem.start_time)
    );
    let endDate, endTime, newSchedule, startDate, startTime;
    if (eventType === "resize") {
      endDate = moment(time).format("YYYY-MM-DD");
      endTime = moment(time).format("HH:mm");
      newSchedule = {
        schedule: { id: itemId, time_to: endTime, end_date: endDate },
      };
    } else {
      startDate = moment(time).format("YYYY-MM-DD");
      startTime = moment(time).format("HH:mm");
      endDate = moment(time)
        .add(duration.asHours(), "hour")
        .format("YYYY-MM-DD");
      endTime = moment(time).add(duration.asHours(), "hour").format("HH:mm");
      newSchedule = {
        schedule: {
          id: itemId,
          time_from: startTime,
          date: startDate,
          time_to: endTime,
          end_date: endDate,
          group_id: groups[newGroupOrder].id,
        },
      };
    }
    axios
      .put(`/admin/schedules/${itemId}/update_record`, newSchedule)
      .then((response) => {
        const { schedule } = response.data;
        const updatedSchedule = {
          id: schedule.id,
          group: schedule.group,
          title: schedule.title,
          start_time: moment(new Date(schedule.start_time)),
          end_time: moment(new Date(schedule.end_time)),
          itemProps: {
            onDoubleClick: () => {
              window.location.href = `/admin/schedules/${schedule.id}`;
            },
            style: {
              background: schedule.color,
            },
          },
        };
        setItems((prevState) => {
          const newState = prevState.filter((item) => item.id !== itemId);
          return [...newState, updatedSchedule];
        });
      });
  };

  const showTimeLine = groups.length > 0;

  return (
    <Fragment>
      {showTimeLine ? (
        <Timeline
          groups={groups}
          items={items}
          onItemDrag={onItemDragHandler}
          defaultTimeStart={moment().add(-12, "hour")}
          defaultTimeEnd={moment().add(12, "hour")}
        />
      ) : (
        <Box
          justifyContent="center"
          display="flex"
          sx={{ minHeight: "20vh" }}
          alignItems="center"
        >
          <p>No data found</p>
        </Box>
      )}
    </Fragment>
  );
};

export default TimelineComponent;
