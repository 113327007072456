import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";

const FilterComponent = ({ label, values, onFilter }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    setSelectedValues(values);
  }, []);

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setSelectedValues(value);
    onFilter(value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={onChangeHandler}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Stack>
        )}
      >
        {values.map((val) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterComponent;
