import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  formatDateUS,
  toHoursAndMinutes,
  formatTableData,
} from "../../helpers";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#616161EB",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #616161EB",
    borderRadius: "4px",
  },
}));

const tableCustomStyles = {
  headRow: {
    style: {
      color: "#ffffff",
      textShadow: "2px 4px 3px rgba(0,0,0,0.3)",
      fontWeight: "normal",
      backgroundColor: "#00bbf2",
      zIndex: 100,
    },
  },
  rows: {
    style: {
      backgroundColor: "#FAFAFA",
    },
    stripedStyle: {
      backgroundColor: "#FFFFFF",
    },
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Rows per page",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All flights",
};

function getCssClass(value) {
  if (value.includes("CM")) return "cond_cell cm_cell";
  else if (value.includes("LA")) return "cond_cell la_cell";
  else if (value.includes("SY")) return "cond_cell sy_cell";
  return "default";
}

const FlightTracker = ({ rows, onClick, services, locale }) => {
  const columns = [
    {
      name: locale("date_a").toUpperCase(),
      selector: (row) => row.date_a,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.date_a}
        </span>
      ),
    },
    {
      name: locale("date_d").toUpperCase(),
      selector: (row) => row.date_d,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.date_d}
        </span>
      ),
    },
    {
      name: locale("inbound").toUpperCase(),
      selector: (row) => row.in,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.in}
        </span>
      ),
    },
    ,
    {
      name: locale("outbound").toUpperCase(),
      selector: (row) => row.out,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.out}
        </span>
      ),
    },
    {
      name: locale("tail_number_a").toUpperCase(),
      selector: (row) => row.reg1,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.reg1}
        </span>
      ),
    },
    {
      name: locale("tail_number_d").toUpperCase(),
      selector: (row) => row.reg2,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.reg2}
        </span>
      ),
    },
    {
      name: locale("from").toUpperCase(),
      selector: (row) => row.from,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.from}
        </span>
      ),
    },
    {
      name: "DEST",
      selector: (row) => row.dest,
      sortable: true,
      cell: (row) => (
        <span onClick={(e) => onClick(row, e)} className={getCssClass(row.in)}>
          {row.dest}
        </span>
      ),
    },
    {
      name: "STA",
      selector: (row) => row.sta,
      sortable: true,
    },
    {
      name: "ETA",
      selector: (row) => row.eta,
      sortable: true,
    },
    {
      name: "ATA",
      selector: (row) => row.ata,
      sortable: true,
    },
    {
      name: "STD",
      selector: (row) => row.std,
      sortable: true,
    },
    {
      name: "ETD",
      selector: (row) => row.etd,
      sortable: true,
    },
    {
      name: "ATD",
      selector: (row) => row.atd,
      sortable: true,
    },
    {
      name: locale("status_a").toUpperCase(),
      selector: (row) => row.flight_status,
      cell: (row) => (
        <HtmlTooltip
          title={
            <div style={{ width: "60px", height: "60px", padding: "4px" }}>
              <CircularProgressbar
                value={row.progress_percent1}
                text={`${row.progress_percent1}%`}
                variant={
                  row.progress_percent1 == 0 ? "indeterminate" : "determinate"
                }
                styles={buildStyles({
                  textColor: "#e4eaec",
                  pathColor: "#00BBF2",
                  trailColor: "#e4eaec",
                })}
              />
            </div>
          }
        >
          <span>{row.flight_status}</span>
        </HtmlTooltip>
      ),
      sortable: true,
    },
    {
      name: locale("status_d").toUpperCase(),
      selector: (row) => row.flight_dstatus,
      cell: (row) => (
        <HtmlTooltip
          title={
            <div style={{ width: "60px", height: "60px", padding: "4px" }}>
              <CircularProgressbar
                value={row.progress_percent2}
                text={`${row.progress_percent2}%`}
                variant={
                  row.progress_percent2 == 0 ? "indeterminate" : "determinate"
                }
                styles={buildStyles({
                  textColor: "#e4eaec",
                  pathColor: "#00BBF2",
                  trailColor: "#e4eaec",
                })}
              />
            </div>
          }
        >
          <span>{row.flight_dstatus}</span>
        </HtmlTooltip>
      ),
      sortable: true,
    },
    {
      name: locale("gate_a").toUpperCase(),
      selector: (row) => row.gate,
      sortable: true,
    },
    {
      name: locale("gate_d").toUpperCase(),
      selector: (row) => row.dgate,
      sortable: true,
    },
    {
      name: locale("scheduled_service").toUpperCase(),
      selector: (row) => row.service,
      sortable: true,
    },
    {
      name: "Flight Aware ID",
      selector: (row) => row.fa_flight_id,
      omit: true,
    },
  ];

  let cookieDate = localStorage.getItem("search_date");
  let rowsData = formatTableData(rows, cookieDate, services);

  const ExpandedComponent = ({ data }) => {
    let selectedServices = services.filter(
      (service) =>
        (service.flight_id === parseInt(data.id) &&
          service.flight_date === cookieDate) ||
        (service.flight_id === parseInt(data.id) &&
          service.flight_date2 === cookieDate)
    );
    let exist = selectedServices.length > 0;

    return (
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        {exist ? (
          <Fragment>
            <h4 style={{ marginTop: "15px", marginBottom: "12px" }}>
              <i>{locale("scheduled_service").toUpperCase()}</i>
            </h4>
            <div className="cpanel top">
              <table className="dataTable">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>{locale("type")}</th>
                    <th>{locale("time_from")}</th>
                    <th>{locale("time_to")}</th>
                    <th>Supervisor</th>
                    <th>{locale("crew.other")}</th>
                    <th>{locale("equipment")}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedServices.map((service) => {
                    return (
                      <tr>
                        <td>{service.id}</td>
                        <td>{service.arrival ? "Arrival" : "Departure"}</td>
                        <td>
                          {service.arrival
                            ? toHoursAndMinutes(service.time_from)
                            : toHoursAndMinutes(service.time_dfrom)}
                        </td>
                        <td>
                          {service.arrival
                            ? toHoursAndMinutes(service.time_to)
                            : toHoursAndMinutes(service.time_dto)}
                        </td>
                        <td>
                          {service.supervisor
                            .map((el) => el["value"])
                            .join(", ")}
                        </td>
                        <td>
                          {service.crews.map((el) => el["label"]).join(", ")}
                        </td>
                        <td>
                          {service.equipment
                            .map((el) => el["value"])
                            .join(", ")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <p
            style={{
              margin: "20px auto",
              textAlign: "center",
              fontSize: "13px",
            }}
          >
            <i>NO SCHEDULED SERVICE</i>
          </p>
        )}
      </div>
    );
  };
  // Sorting by Estimated time of arrival and Estimated time of departure
  rowsData =
    rowsData.length > 0
      ? rowsData.sort((a, b) => {
          const [m1, d1, y1] =
            a.date_a !== "-"
              ? a.date_a.split("/")
              : formatDateUS(cookieDate).split("/");
          const [m2, d2, y2] =
            b.date_a !== "-"
              ? b.date_a.split("/")
              : formatDateUS(cookieDate).split("/");
          const date1 = `${y1}-${m1}-${d1}`;
          const date2 = `${y2}-${m2}-${d2}`;
          let eta1 = a.eta === "-" ? (a.sta === "-" ? a.std : a.sta) : a.eta;
          let eta2 = b.eta === "-" ? (b.sta === "-" ? b.std : b.sta) : b.eta;
          eta1 = eta1.length === 4 ? `0${eta1}` : eta1;
          eta2 = eta2.length === 4 ? `0${eta2}` : eta2;
          return new Date(`${date1}T${eta1}`) - new Date(`${date2}T${eta2}`);
        })
      : rowsData;

  return (
    <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
      <DataTable
        columns={columns}
        data={rowsData}
        striped
        fixedHeader
        pointerOnHover
        onRowClicked={onClick}
        customStyles={tableCustomStyles}
        highlightOnHover
        responsive
        pagination
        paginationComponentOptions={paginationComponentOptions}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
      />
    </TableContainer>
  );
};

export default FlightTracker;
