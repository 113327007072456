import React, { useState, useEffect, Fragment } from "react";
import SearchForm from "./ui/SearchForm";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDateUS } from "../helpers";
import axios from "axios";

const WeeklyActivity = ({ airports }) => {
  const [options, setOptions] = useState(airports);
  const [data, setData] = useState([]);

  const getReport = (date_from = "", date_to = "", airport = "") => {
    axios
      .get(
        `/admin/services/weekly_activity?date_from=${date_from}&date_to=${date_to}&airport=${airport}&format=json`
      )
      .then((response) => {
        setData(response.data.services);
      });
  };

  const getLink = (date_from = "", date_to = "", airport = "") => {
    return `/admin/services/weekly_activity?date_from=${date_from}&date_to=${date_to}&airport=${airport}&format=html`;
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <Fragment>
      <SearchForm
        setData={getReport}
        getLink={getLink}
        airports={options}
        airlines={[]}
        showAirlines={false}
      />
      <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="weekly report table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Flight</TableCell>
              <TableCell align="center">Services</TableCell>
              <TableCell align="center">Airport</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{formatDateUS(row.date)}</TableCell>
                <TableCell align="center">{row.flight_number}</TableCell>
                <TableCell align="left">
                  {row.services.map((s) => s.name).join(", ")}
                </TableCell>
                <TableCell align="center">{row.airport}</TableCell>
              </TableRow>
            ))}
            {data.length <= 0 && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <i>NO DATA</i>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default WeeklyActivity;
