import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  FormGroup,
  Checkbox,
  ButtonGroup,
  Grid,
} from "@mui/material";
import Close from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 390,
  maxHeight: "100vh",
  bgcolor: "background.paper",
  overflow: "scroll",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const ReportModal = ({
  open,
  setOpen,
  setIsDisabled,
  extraServices,
  addExtraServices,
  formSubmitProcessor,
}) => {
  const [addServices, setAddServices] = useState("");
  const [label, setLabel] = useState("Submit");
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    addServices === "yes" ? setLabel("Add") : setLabel("Submit");
    addServices === "no" ? setSelectedServices([]) : null;
  }, [addServices, setAddServices]);

  const handleClose = () => {
    setAddServices("");
    setOpen(false);
    setIsDisabled(false);
  };

  const addServicesHandler = (e) => {
    setAddServices(e.target.value);
  };

  const checkBoxHandler = (e) => {
    const id = parseInt(e.target.id);
    if (e.target.checked) {
      setSelectedServices((prevState) => [...prevState, id]);
    }
    if (!e.target.checked) {
      setSelectedServices((prevState) => {
        const newState = prevState.filter((el) => el !== id);
        return newState;
      });
    }
  };

  const btnClickHandler = () => {
    setOpen(false);
    label === "Add"
      ? addExtraServices(selectedServices)
      : formSubmitProcessor();
  };

  const btnDisabled =
    addServices === "" ||
    (addServices === "yes" && selectedServices.length <= 0);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton onClick={handleClose} sx={{ float: "right" }}>
              <Close />
            </IconButton>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: "20px" }}
            >
              Triangle Services Aviation
            </Typography>
            <Grid container sx={{ overflow: "auto" }}>
              <Grid item sm={12}>
                <FormControl>
                  <FormLabel id="extra-services-label">
                    Do you want to add any extra service?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="extra-services-label"
                    name="row-radio-buttons-group"
                    value={addServices}
                    onChange={addServicesHandler}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {addServices === "yes" &&
                extraServices.map((service) => {
                  return (
                    <Grid item sm={12} key={service.id}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={checkBoxHandler}
                              id={service.id.toString()}
                            />
                          }
                          label={service.name}
                        />
                      </FormGroup>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid
              sx={{ mt: "20px" }}
              container
              spacing={1}
              display="flex"
              justifyContent="center"
            >
              <ButtonGroup
                size="large"
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  variant="contained"
                  color="info"
                  disabled={btnDisabled}
                  onClick={btnClickHandler}
                >
                  {label}
                </Button>
              </ButtonGroup>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ReportModal;
