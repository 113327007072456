import { Alert } from "@mui/material";
import React from "react";

const MyAlert = ({ message, severity, variant }) => {
  return (
    <div style={{ margin: "10px 10px 15px" }}>
      <Alert severity={severity} variant={variant}>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Alert>
    </div>
  );
};

export default MyAlert;
