import React from "react";
import Modal from "react-modal";
import { customStyles } from "../../styles";
import Close from "@mui/icons-material/Close";

Modal.setAppElement("#main");

const ModalDialog = ({
  title,
  closeClicked,
  showForm,
  contentLabel,
  children,
}) => {
  return (
    <Modal
      isOpen={showForm}
      style={customStyles}
      closeTimeoutMS={600}
      contentLabel={contentLabel}
    >
      <div style={{ mb: "10px", display: "flex", justifyContent: "right" }}>
        <Close onClick={closeClicked} sx={{ cursor: "pointer" }} />
      </div>
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      {children}
    </Modal>
  );
};

export default ModalDialog;
