import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        actions: "Actions",
        diverted: "Diverted",
        charter: "Charter",
        destination: "Destination",
        origin: "Origin",
        time: "Time",
        signature: "Signature",
        signer_name: "Signer Name",
        none: "None",
        deleted: "Deleted",
        select: "Select...",
        aoa_operations: "AOA Operations",
        create_signature: "Create Signature",
        safety_topics: "Required Safety Topics",
        equipment_inspection: "Equipment Inspection",
        crew_briefing: "Crew Briefing",
        yes_to_all: "Yes To All",
        yes: "Yes",
        no: "No",
        reason: "Reason",
        no_internet: "No Internet",
        add_comment: "Add Comment",
        add_service: "Add Service",
        edit_comment: "Edit Comment",
        delete_draft: "Delete Draft",
        save_draft: "Save Draft",
        report: "Report",
        ramp_checklist: "Ramp Supervisor Required Actions",
        ramp_report: "Ramp Flight Operation Report",
        cleaning_report: "Cabin Service Report",
        delay: "Delay",
        no_flights: "NO SCHEDULED FLIGHTS",
        iata: "IATA Code",
        comment: "Comment",
        new: "New",
        edit: "Edit",
        save: "Save",
        changed_sta: "Changed Scheduled Time",
        cabin_service_report: "Cabin Service Report",
        planned_service_time1: "Planned service time at arrival",
        planned_service_time2: "Planned service time at departure",
        arrival: "Arrival",
        departure: "Departure",
        turnaround: "Turnaround",
        select_option: "Please select one of the options",
        search: "Search",
        additional_services: "Additional Services",
        reg_num: "Reg Num",
        code: "Code",
        approved: "Approved",
        declined: "Declined",
        pending: "Pending",
        required: "required",
        create: "Create",
        clear: "Clear",
        close: "Close",
        update: "Update",
        cancel: "Cancel",
        canceled: "Canceled",
        scheduled: "Scheduled",
        cancelation_form: "Cancelation Form",
        note: "Note",
        other: "OTHER",
        mostly_used: "MOSTLY USED",
        services_performed: "TYPE OF SERVICE PERFORMED",
        refresh: "Refresh",
        choose_option: "Please choose an option",
        dashboard: "Dashboard",
        flight: "Flight",
        schedule: "Schedule",
        schedule_board: "Schedule Board",
        scheduled_service: "Scheduled Service",
        schedule_service: "Schedule Service",
        tab1: "Weekly Flight Schedule and Crew Assignment",
        tab2: "Flights Live Data and Service Scheduling",
        inbound: "Inbound",
        outbound: "Outbound",
        city: "City",
        closed: "Closed",
        sta: "Scheduled Time",
        ata: "Actual Time",
        ac_type: "A/C Type",
        crew: { one: "Crew", other: "Crews" },
        arr_dep: "ARR/DEP",
        services: "Services",
        download: "Download",
        calendar: "Calendar",
        date_a: "Date (A)",
        date_d: "Date (D)",
        date_notified: "Date Notified",
        from: "From",
        gate_a: "Gate (A)",
        gate_d: "Gate (D)",
        gate_w: "Gate",
        gate: "Gate In/Out",
        cargo: "Cargo",
        porter: "Porter",
        first_bag: "First Bag On/Off",
        last_bag: "Last Bag On/Off",
        first_cargo: "First Cargo On/Off",
        last_cargo: "Last Cargo On/Off",
        tail_number_a: "Tail Number (A)",
        tail_number_d: "Tail Number (D)",
        tail_number: "Tail Number",
        start_time_a: "Start Time (A)",
        start_time_d: "Start Time (D)",
        end_time_a: "End Time (A)",
        end_time_d: "End Time (D)",
        equipment: "Equipment",
        add_flight: "Add Flight",
        airline: "Airline",
        airport: "Airport",
        flight_type: "Flight Type",
        ad_type: "Arrival/Departure",
        flight_number: "Flight Number",
        flight_date: "Flight Date",
        next: "Next",
        submit: "Submit",
        status: "Status",
        status_a: "Status (A)",
        status_d: "Status (D)",
        back: "Back",
        no_data: "No data found.",
        date: "Date",
        new_customer: "New Customer",
        existing_customer: "Existing Customer",
        customer_email: "Customer E-mail",
        requested_by: "Requested by",
        ramp_control: "Ramp Control",
        ramp: "Ramp",
        cabin: "Cabin",
        security: "Security",
        aircraft_type: "Aircraft Type",
        type: "Type",
        time_from: "Time From",
        time_to: "Time To",
        date_from: "Date From",
        date_to: "Date To",
        equipment: "Equipment",
        error_messages: {
          flight_listed:
            "Exists. Enter a flight number that is not on the list.",
          flight_number:
            "Incorrect format. Flight number should contain uppercase letters and numbers",
          main: "Something went wrong. Please try again.",
          main1: "Something Went Wrong. Please see the errors below.",
          incorrect_flight_number:
            "Incorrect format. Flight number should contain uppercase letters and numbers",
          incorrect_format: "Incorrect format.",
          not_blank: "Can't be blank",
          not_empty: "Can't be empty",
          select_option: "This field is required. Please select one option",
          missing_data:
            "Some data is missing for this option. Please try another one.",
          signature: {
            not_blank: "Signature can't be blank",
          },
          time: {
            blank_or_equal: "can't be blank and can't be equal to",
          },
        },
        success_messages: {
          gate: "Gate was successfully updated.",
          comment: "Comment was successfully saved.",
          trl_form: "Additional Service form was successfully saved",
          service_created: "Service was successfully created",
          service_updated: "Service was successfully updated",
          services_created: "Services were successfully created",
          form_submitted: "Your form was successfully submitted",
          report_saved: "Report was successfully saved",
          report_updated: "Report was successfully updated",
        },
        helper_text: {
          greater_date_from: "Must be greater than Date From",
          comment: "Type your comment in the box and click the Save button.",
          ramp_report: "You can make changes in this report until",
          signature: "Please create a signature",
        },
        attributes: {
          op201026_form: {
            q_1: "Assign specific functions and responsibilities to the crew",
            q_2: "Verify that each crew member has the proper operator ID for assigned equipment",
            q_3: "Check the LIR",
            q_4: "All required ground support is ready",
            q_5: "- 3-stop break check for approaching equipment",
            q_6: "- Equipment marshal plan",
            q_7: "- All personnel reminded that PPE is required at all times on the ramp",
            q_8: "- All personnel have Vest, Safety Shoes, Hearing Protect, Gloves",
            q_9: "- Knee protection required in narrow body AC",
            q_10: "- Equipment inspection is required prior to use on flight",
            q_11: "- Cargo hold inspection is required at arrival and after offload",
            q_12: "- 360 inspection of AC is required at arrival and departure",
            q_13: "Conduct FOD walk",
            q_14: "Tires",
            q_15: "Lights",
            q_16: "Parking Brake",
            q_17: "Brakes",
            q_18: "Horn",
            q_19: "Reverse Light/Alert",
            q_20: "Hitch",
            q_21: "Belt",
            q_22: "Fire Extinguisher",
            q_23: "Dolly Locks",
            q_24: "Open Carts",
            q_25: "Seat Belts Working",
            q_26: "Reflective Strips",
            q_27: "Proper tow bar for aircraft has been staged",
            q_28: "Wing walkers are properly positioned",
            q_29: "Chocks and cones positioned per airline requirements",
            q_30: "360 inspection is complete",
            q_31: "Equipment approach after beacon is off",
            q_32: "Equipment approach 3 MPH, 3-stop break checks",
            q_33: "Marshal used for all connecting equipment",
            q_34: "Inspect cargo hold upon arrival, damaged PLU or other damage",
            q_35: "Priority bags sent to FIS first",
            q_36: "Inspect cargo hold after unload, no damage, spills, remaining cargo/bags",
            q_37: "No FOD on the equipment",
            q_38: "Tugs at safe speed",
            q_39: "Equipment performing 3-stop break checks",
            q_40: "Equipment operators and authorized passengers using seatbelts",
            q_41: "Tugs transporting no more than three dollies and/or baggage carts",
            q_42: "Cargo loader operator MUST compare LIR with actual element weight and number",
            q_43: "Only authorized personnel are in the Safety Diamond",
            q_44: "ULD's have ODLN attached and damage to the unit is within those acceptable limits",
          },
        },
      },
    },
    es: {
      translation: {
        actions: "Acciones",
        diverted: "Desviado",
        charter: "Carta",
        destination: "Destino",
        origin: "Origen",
        time: "tiempo",
        signature: "Firma",
        signer_name: "Nombre del firmante",
        none: "Ninguno",
        deleted: "Eliminado",
        select: "Seleccione...",
        aoa_operations: "Operaciones de AOA",
        create_signature: "Crear Firma",
        safety_topics: "Temas de Seguridad Obligatorios",
        equipment_inspection: "Inspección de equipos",
        crew_briefing: "Reunión informativa para la tripulación",
        yes_to_all: "Sí a todo",
        yes: "Si",
        no: "No",
        reason: "Razón",
        no_internet: "Sin Internet",
        add_comment: "Añadir Comentario",
        add_service: "Añadir Servicio",
        edit_comment: "Editar Comentario",
        delete_draft: "Eliminar Borrador",
        save_draft: "Guardar Borrador",
        report: "Informe",
        ramp_checklist: "Supervisor de rampa Acciones requeridas",
        ramp_report: "Informe de operación de vuelo en rampa",
        cleaning_report: "Informe de servicio de cabina",
        delay: "Retraso",
        no_flights: "SIN VUELOS REGULARES",
        iata: "Código IATA",
        comment: "Comentario",
        new: "Nuevo",
        edit: "Editar",
        save: "Guardar",
        changed_sta: "Hora programada modificada",
        cabin_service_report: "Informe de servicio de cabina",
        planned_service_time1: "Tiempo de servicio previsto a la llegada",
        planned_service_time2: "Tiempo de servicio previsto a la salida",
        arrival: "Llegada",
        departure: "Salida",
        turnaround: "Turnaround",
        select_option: "Seleccione una de las opciones",
        search: "Buscar",
        additional_services: "Servicios Adicionales",
        reg_num: "Número de registro",
        code: "Código",
        approved: "Aprobado",
        declined: "Rechazado",
        pending: "Pendiente",
        required: "obligatorio",
        create: "Crear",
        clear: "Claro",
        close: "Cerrar",
        update: "Actualización",
        cancel: "Cancelar",
        canceled: "Cancelado",
        scheduled: "Programado",
        cancelation_form: "Formulario de Cancelación",
        other: "OTROS",
        note: "Nota",
        mostly_used: "CASI TODOS USADOS",
        services_performed: "TIPO DE SERVICIO PRESTADO",
        refresh: "Actualizar",
        choose_option: "Elija una opción",
        dashboard: "Tablero",
        flight: "Vuelo",
        schedule: "Horario",
        schedule_board: "Tabla de Horarios",
        scheduled_service: "Servicio Programado",
        schedule_service: "Programar Servicio",
        tab1: "Programa Semanal de Vuelos y Asignación de Tripulaciones",
        tab2: "Datos de Vuelos en Directo y Programación de Servicios",
        inbound: "Entrada",
        outbound: "Salida",
        city: "Ciudad",
        closed: "Terminado",
        sta: "Hora programada",
        ata: "Hora actual",
        ac_type: "Tipo de avión",
        crew: { one: "Tripulación", other: "Cuadrillas" },
        arr_dep: "LLE/SAL",
        services: "Servicios",
        download: "Descargar",
        calendar: "Calendario",
        date_a: "Fecha (L)",
        date_d: "Fecha (S)",
        date_notified: "Fecha de notificación",
        from: "En",
        gate_a: "Puerta (L)",
        gate_d: "Puerta (S)",
        gate_w: "Puerta",
        gate: "Entrada/Salida",
        cargo: "Carga",
        porter: "Porter",
        first_bag: "Primera Bolsa En/Fuera",
        last_bag: "Última Bolsa En/Fuera",
        first_cargo: "Primera Carga En/Fuera",
        last_cargo: "Última Carga En/Fuera",
        start_time_a: "Hora de Inicio (L)",
        start_time_d: "Hora de Inicio (S)",
        end_time_a: "Fin del Tiempo (L)",
        end_time_d: "Fin del Tiempo (S)",
        tail_number_a: "Número de cola (L)",
        tail_number_d: "Número de cola (S)",
        tail_number: "Número de cola",
        equipment: "Equipamiento",
        add_flight: "Añadir Vuelo",
        airline: "Aerolínea",
        airport: "Aeropuerto",
        flight_type: "Tipo de vuelo",
        ad_type: "Llegada/Salida",
        flight_number: "Número de vuelo",
        flight_date: "Fecha de vuelo",
        next: "Siguiente",
        submit: "Enviar",
        status: "Estado",
        status_a: "Estado (L)",
        status_d: "Estado (S)",
        back: "Volver",
        no_data: "No se han encontrado datos.",
        date: "Fecha",
        new_customer: "Nuevo cliente",
        existing_customer: "Cliente existente",
        customer_email: "Correo electrónico del cliente",
        requested_by: "Solicitado por",
        ramp_control: "Control de rampa",
        ramp: "Rampa",
        cabin: "Cabina",
        security: "Seguridad",
        aircraft_type: "Tipo de avión",
        type: "Tipo",
        time_from: "Hora desde",
        time_to: "Hora de",
        date_from: "Fecha de",
        date_to: "Fecha a",
        equipment: "Equipo",
        error_messages: {
          flight_listed:
            "Existe. Introduzca un número de vuelo que no esté en la lista.",
          flight_number:
            "Formato incorrecto. El número de vuelo debe contener letras mayúsculas y números",
          main: "Algo ha ido mal. Por favor, inténtelo de nuevo.",
          main1: "Algo ha ido mal. Consulte los errores a continuación.",
          incorrect_flight_number:
            "Formato incorrecto. El número de vuelo debe contener letras mayúsculas y números",
          incorrect_format: "Formato incorrecto.",
          not_blank: "No puede estar en blanco",
          not_empty: "No puede estar vacío",
          select_option: "Este campo es obligatorio. Seleccione una opción",
          missing_data:
            "Faltan algunos datos para esta opción. Prueba con otra.",
          signature: {
            not_blank: "La firma no puede estar en blanco",
          },
          time: {
            blank_or_equal: "no puede estar en blanco y no puede ser igual a",
          },
        },
        success_messages: {
          gate: "La puerta se ha actualizado correctamente",
          comment: "El comentario se ha guardado correctamente.",
          trl_form:
            "El formulario de servicio adicional se ha guardado correctamente",
          service_created: "El servicio se ha creado correctamente",
          service_updated: "El servicio se ha actualizado correctamente",
          services_created: "Los servicios se han creado correctamente",
          form_submitted: "Su formulario se ha enviado correctamente",
          report_saved: "El informe se ha guardado correctamente",
          report_updated: "El informe se ha actualizado correctamente",
        },
        helper_text: {
          greater_date_from: "Debe ser mayor que Fecha desde",
          comment:
            "Escriba su comentario en la casilla y pulse el botón Guardar.",
          ramp_report: "Puede realizar cambios en este informe hasta",
          signature: "Por favor, cree una firma",
        },
        attributes: {
          op201026_form: {
            q_1: "Asignar funciones y responsabilidades específicas a la tripulación",
            q_2: "Verificar que cada miembro de la tripulación tiene la identificación de operador adecuada para el equipo asignado.",
            q_3: "Compruebe el LIR",
            q_4: "Todo el apoyo terrestre necesario está listo",
            q_5: "- Control de pausa de 3 paradas para equipos que se aproximan",
            q_6: "- Plan del jefe de equipo",
            q_7: "- Se recuerda a todo el personal que el EPI es obligatorio en todo momento en la rampa",
            q_8: "- Todo el personal dispone de chaleco, calzado de seguridad, protección auditiva y guantes.",
            q_9: "- Protección de rodillas necesaria en AC de carrocería estrecha",
            q_10: "- Se requiere la inspección del equipo antes de su uso en vuelo",
            q_11: "- Inspección de la bodega de carga a la llegada y después de la descarga",
            q_12: "- 360 inspecciones de aire acondicionado a la llegada y a la salida",
            q_13: "Paseo FOD",
            q_14: "Neumáticos",
            q_15: "Luces",
            q_16: "Freno de mano",
            q_17: "Frenos",
            q_18: "Bocina",
            q_19: "Luz de marcha atrás/Alerta",
            q_20: "Enganche",
            q_21: "Cinturón",
            q_22: "Extintor",
            q_23: "Cerraduras Dolly",
            q_24: "Carros abiertos",
            q_25: "Cinturones de seguridad en funcionamiento",
            q_26: "Bandas reflectantes",
            q_27: "Se ha puesto en escena la barra de remolque adecuada para el avión",
            q_28: "Los andadores se colocan correctamente",
            q_29: "Calzos y conos colocados según los requisitos de la compañía aérea",
            q_30: "360 inspección completa",
            q_31: "Aproximación del equipo después de apagar la baliza",
            q_32: "Aproximación del equipo a 3 MPH, controles de frenado de 3 paradas",
            q_33: "Mariscal utilizado para todos los equipos de conexión",
            q_34: "Inspeccionar la bodega de carga a la llegada, PLU dañado u otros daños.",
            q_35: "Las bolsas prioritarias se envían primero al FIS",
            q_36: "Inspeccionar la bodega de carga después de la descarga, sin daños, derrames, carga/bolsas restantes.",
            q_37: "No hay FOD en el equipo",
            q_38: "Remolcadores a velocidad segura",
            q_39: "Equipos que realizan controles de interrupción de 3 paradas",
            q_40: "Operadores de equipos y pasajeros autorizados que utilicen cinturones de seguridad",
            q_41: "Remolcadores que no transporten más de tres plataformas rodantes y/o carros portaequipajes",
            q_42: "El operador del cargador DEBE comparar el LIR con el peso y el número real de elementos.",
            q_43: "En el Diamante de Seguridad sólo hay personal autorizado",
            q_44: "Los ULD llevan ODLN y los daños a la unidad están dentro de los límites aceptables.",
          },
        },
      },
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
