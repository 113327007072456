import React, { Fragment, useState, useEffect } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import OutlinedDiv from "./OutlinedDiv";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import {
  TextField,
  InputLabel,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  IconButton,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import SignatureModal from "./SignatureModal";
import { validateService } from "../../helpers";
import { red, green } from "@mui/material/colors";

const ContractService = ({
  service,
  onChange,
  onDelete,
  errors,
  setErrors,
  arrival,
  locale,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    onChange(service.id, "signature", imageURL);
    errors.signature = imageURL == null;
  }, [imageURL, setImageURL]);

  const validateInputHandler = (e) => {
    const errorsObject = validateService(service);
    const { name } = e.target;
    errors[name] = errorsObject[name];
    setErrors((prevState) => {
      const newState = { ...prevState };
      newState[service.id.toString()] = { ...errors };
      return newState;
    });
  };

  return (
    <OutlinedDiv label={service.name}>
      <Grid container spacing={1} alignItems="center" justifyContent="right">
        {![
          "Safety Cones",
          "Wing Walkers",
          "On-Blocks",
          "Off-Blocks",
          "Baggage Unloading",
          "Baggage Loading",
          "Cargo Unloading",
          "Cargo Loading",
        ].includes(service.name) &&
          arrival && (
            <IconButton
              onClick={() => onChange(service.id, "shared", !service.shared)}
            >
              {service.shared ? (
                <ToggleOnIcon sx={{ color: green[600] }} />
              ) : (
                <ToggleOffIcon sx={{ color: red[600] }} />
              )}
            </IconButton>
          )}
        {service.name === "Cabin Services" ? (
          <br />
        ) : service.shared && !arrival ? (
          <br />
        ) : (
          ![
            "Safety Cones",
            "Wing Walkers",
            "On-Blocks",
            "Off-Blocks",
            "Baggage Unloading",
            "Baggage Loading",
            "Cabin Services",
          ].includes(service.name) && (
            <IconButton onClick={onDelete.bind(this, service.id)}>
              <DeleteOutlinedIcon sx={{ color: red[600] }} id={service.id} />
            </IconButton>
          )
        )}
      </Grid>
      {service.enable_yes_no && (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="left"
          sx={{ paddingBottom: "10px" }}
        >
          <Grid item xs={6} md={2}>
            <FormLabel id={"yes-no-controlled-radio-buttons-group"}>
              {service.label_yes_no}
            </FormLabel>
          </Grid>
          <Grid item xs={6} md={10}>
            <RadioGroup
              row
              aria-labelledby={"yes-no-controlled-radio-buttons-group"}
              name="controlled-radio-buttons-group"
              value={service.yes_no}
              onChange={(event) =>
                onChange(service.id, "yes_no", event.target.value)
              }
              id="yes-no"
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={locale("yes")}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={locale("no")}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      )}
      {service.enable_unit && (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="left"
          sx={{ paddingBottom: "10px" }}
        >
          {service.label_unit1 && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="unit1">{service.label_unit1}</InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.unit1}
                  helperText={
                    errors.unit1 ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_unit1}
                  id="unit1"
                  name="unit1"
                  type="text"
                  value={service.unit1}
                  onChange={(event) =>
                    onChange(service.id, "unit1", event.target.value)
                  }
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
          {service.label_unit2 && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="unit2">{service.label_unit2}</InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.unit2}
                  helperText={
                    errors.unit2 ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_unit2}
                  id="unit2"
                  name="unit2"
                  type="text"
                  value={service.unit2}
                  onChange={(event) =>
                    onChange(service.id, "unit2", event.target.value)
                  }
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
          {service.label_unit3 && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="unit3">{service.label_unit3}</InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.unit3}
                  helperText={
                    errors.unit3 ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_unit3}
                  id="unit3"
                  name="unit3"
                  type="text"
                  value={service.unit3}
                  onChange={(event) =>
                    onChange(service.id, "unit3", event.target.value)
                  }
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
          {service.label_unit4 && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="unit4">{service.label_unit4}</InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.unit4}
                  helperText={
                    errors.unit4 ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_unit4}
                  id="unit4"
                  name="unit4"
                  value={service.unit4}
                  onChange={(event) =>
                    onChange(service.id, "unit4", event.target.value)
                  }
                  type="text"
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
          {service.label_unit5 && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="unit5">{service.label_unit5}</InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.unit5}
                  helperText={
                    errors.unit5 ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_unit5}
                  id="unit5"
                  name="unit5"
                  type="text"
                  value={service.unit5}
                  onChange={(event) =>
                    onChange(service.id, "unit5", event.target.value)
                  }
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      )}
      {service.enable_start_loc_dest && (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="left"
          sx={{ paddingBottom: "10px" }}
        >
          {service.label_start_loc && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="start_loc">
                  {service.label_start_loc}
                </InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.start_loc}
                  helperText={
                    errors.start_loc ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_start_loc}
                  id="start_loc"
                  name="start_loc"
                  type="text"
                  value={service.start_loc}
                  onChange={(event) =>
                    onChange(service.id, "start_loc", event.target.value)
                  }
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
          {service.label_dest && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="dest">{service.label_dest}</InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={errors.dest}
                  helperText={
                    errors.dest ? locale("error_messages.not_blank") : null
                  }
                  variant="standard"
                  onBlur={validateInputHandler}
                  placeholder={service.label_dest}
                  id="dest"
                  name="dest"
                  type="text"
                  value={service.dest}
                  onChange={(event) =>
                    onChange(service.id, "dest", event.target.value)
                  }
                  fullWidth={true}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      )}
      {service.enable_start_end_time && (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="left"
          sx={{ paddingBottom: "10px" }}
        >
          {service.label_start_time && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="start_time">
                  {service.label_start_time}
                </InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TimePicker
                  showSecond={false}
                  defaultValue={moment(service.start_time)}
                  style={{
                    width: "100%",
                  }}
                  onChange={(time) => {
                    onChange(service.id, "start_time", time._d);
                  }}
                  format={"H:mm"}
                  onClose={(e) =>
                    validateInputHandler({
                      target: { name: "start_time" },
                    })
                  }
                />
                {errors.start_time && (
                  <small style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                    {service.label_start_time}{" "}
                    {service.label_start_time
                      .split(" ")
                      .map((w) => w.toLowerCase())
                      .includes("time")
                      ? null
                      : "time"}{" "}
                    {locale("error_messages.not_blank")}
                  </small>
                )}
              </Grid>
            </Fragment>
          )}

          {service.label_end_time && (
            <Fragment>
              <Grid item xs={6} md={2}>
                <InputLabel htmlFor="end_time">
                  {service.label_end_time}
                </InputLabel>
              </Grid>
              <Grid item xs={6} md={4}>
                <TimePicker
                  showSecond={false}
                  defaultValue={moment(service.end_time)}
                  style={{
                    width: "100%",
                  }}
                  onChange={(time) => {
                    onChange(service.id, "end_time", time._d);
                  }}
                  format={"H:mm"}
                  onClose={(e) =>
                    validateInputHandler({
                      target: { name: "end_time" },
                    })
                  }
                />
                {errors.end_time && (
                  <small style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                    {service.label_end_time}{" "}
                    {service.label_end_time
                      .split(" ")
                      .map((w) => w.toLowerCase())
                      .includes("time")
                      ? null
                      : "time"}{" "}
                    {locale("error_messages.time.blank_or_equal")}{" "}
                    {service.label_start_time} {locale("time")}
                  </small>
                )}
              </Grid>
            </Fragment>
          )}
        </Grid>
      )}
      {openModal && (
        <SignatureModal setOpenModal={setOpenModal} setImageURL={setImageURL} />
      )}
      {service.signature_required && (
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="left"
          sx={{ paddingBottom: "10px" }}
        >
          <Grid item xs={6} md={2}>
            <InputLabel htmlFor="dest"> {locale("signer_name")}</InputLabel>
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              error={errors.signer_name}
              helperText={
                errors.signer_name ? locale("error_messages.not_blank") : null
              }
              variant="standard"
              onBlur={validateInputHandler}
              id="signer_name"
              name="signer_name"
              fullWidth={true}
              value={service.signer_name}
              placeholder="Signer Name"
              onChange={(event) =>
                onChange(service.id, "signer_name", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="outlined"
              color="info"
              onClick={() => setOpenModal(true)}
            >
              <CreateIcon /> {locale("signature")}
            </Button>
            {errors.signature && (
              <small style={{ color: "#d32f2f" }}>
                {locale("helper_text.signature")}
              </small>
            )}
          </Grid>
        </Grid>
      )}
    </OutlinedDiv>
  );
};

export default ContractService;
